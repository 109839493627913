import { Plugins } from '@capacitor/core';
import { IonChip, IonIcon, IonLabel, withIonLifeCycle } from '@ionic/react';
import { checkmarkCircle, checkmarkDoneCircle, closeCircle, flash, playCircle, star, volumeHigh } from 'ionicons/icons';
import React, { createRef } from 'react';
import SQLStorage from '../SQLStorage';
import '../styles/FlashcardEditorModal.scss';
import Utilities from '../Utilities';

import { isPlatform } from '@ionic/react';
import { SwipeableDrawer } from '@material-ui/core';

const { Clipboard, Keyboard } = Plugins;
const stringSimilarity = require("string-similarity");

class FlashcardEditModal extends React.Component {

   constructor(props) {

        super(props);
        this.modalRef = createRef();
        this.slider = createRef();
        this.state = {
            flashcard: this.props.flashcard,
            stats: {},
            slideIndex: 0,
            sliderHeight: 0,
        }

        Utilities.trackEvent("Flashcard", "Flashcard Open Edit", this.props.flashcard ? this.props.flashcard.id : "");

        if (isPlatform("ios") || isPlatform("android")) {

            //  // Keyboard.setResizeMode("body")

            Keyboard.addListener('keyboardWillShow', (info) => {
                this.setState({ keyboardShowing: true })
                window.keyboardHeight = info.keyboardHeight;
                if (isPlatform("ios")) {
                    if (this.modalRef.current) {
                        this.modalRef.current.parentNode.style.setProperty('bottom', info.keyboardHeight + "px");
                        // this.modalRef.current.style.setProperty('margin-top', (info.keyboardHeight + (window.notchSizeTop || 0) ) + "px", "important");
                    }
                }
            });

            Keyboard.addListener('keyboardWillHide', () => {
                this.setState({ keyboardShowing: false })
                if (isPlatform("ios")) {
                    if (this.modalRef.current) {
                        this.modalRef.current.parentNode.style.setProperty('bottom', 0 + "px");
                        // this.modalRef.current.style.setProperty('margin-top', "12px", "important");
                    }
                }
            });

        }
    }

    componentDidMount() {
        this.calculateStats();
        Utilities.onDrawerShow();
        setTimeout(() => {
            this.adjustSliderHeight();
        }, 300)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open) {
            Utilities.onDrawerShow();
            this.setState({
                flashcard: nextProps.flashcard,
                slideIndex: 0
            }, function() {
                this.calculateStats();
                this.adjustSliderHeight();
            })
        }
    }

    adjustSliderHeight() {
        const el = document.getElementById("card-slide-" + this.state.slideIndex);
        console.log(el)
        this.setState({
            sliderHeight: el ? (el.clientHeight + "px") : (320 + 'px')
        })
    }

    async toggleStar() {
        let card = this.state.flashcard;
        Utilities.trackEvent("Flashcard", "Flashcard Star", this.state.flashcard.id, card.star ? 0 : 1);

        card.history.push( ((card.star) ? "2" : "1") + "_star_" + Utilities.timestamp() );
        card.star = card.star ? null : "t"

        this.setState({ flashcard: card })
        await SQLStorage.saveCard(card, false, true)
    }

    async setStrength(strength) {
        let card = this.state.flashcard;
        Utilities.trackEvent("Flashcard", "Flashcard Strength Update", this.state.flashcard.id, strength);

        let oldStrength = card.strength;

        if (oldStrength === strength) {
            return;
        }

        card.strength = strength;
        card.history.push("2_strength-" + oldStrength + "_" + Utilities.timestamp());
        card.history.push("1_strength-" + card.strength + "_" + Utilities.timestamp());

        this.setState({ flashcard: card })
        await SQLStorage.saveCard(card, false, true)
    }

    async pronounceWord(term, definition) {

        if ((!window.activated) && (!Utilities.withinFreeTrial("pronunciation"))) {
            Utilities.onDrawerHide();
            this.props.onClose(null);
            window.showSubscriptionModal("pronunciation")
            return;
        }

        if (!window.canPronounceTerm && !window.canPronounceDefinition) {
            window.toast(window.ln.unsupportedLangP, "error");
            return;
        }

        this.setState({ pronouncing: true })

        if (window.canPronounceTerm && term)
            await Utilities.pronounceWord(term, window.INSTANCE_CONFIG.LANGUAGE_PACK_TERM);

        if (window.canPronounceDefinition && definition)
            await Utilities.pronounceWord(definition, window.INSTANCE_CONFIG.LANGUAGE_PACK_DEFINITION);

        this.setState({ pronouncing: false })

    }

    async calculateStats() {
        let item = this.state.flashcard;
        if (item && typeof item.history === "object") {
            let practiceCount = 0;
            let alertCount = 0;
            let flashcardCount = 0;

            let revisionSchedule = await SQLStorage.getSetting("spacedRepetitionCycles");
            revisionSchedule = revisionSchedule ? JSON.parse(revisionSchedule).length : 0;

            for (let i = 0; i < item.history.length; i++) {
                const happening = item.history[i].split("_");
                const actionCode = happening[0];
                if (actionCode === "10") {
                    alertCount++;
                } else if (actionCode === "11") {
                    flashcardCount++;
                } else if (actionCode === "10") {
                    alertCount++;
                } else if (actionCode === "pronounce" || actionCode === "select" || actionCode === "type" || actionCode.indexOf("term") !== -1 || actionCode.indexOf("definition") !== -1) {
                    practiceCount++;
                }
            }

            let stats = {
                reviewCount: item.reviewCount || "0",
                practiceCount: practiceCount,
                flashcardCount: flashcardCount,
                alertCount: alertCount,
                revisionCycle: (item.revisionCycle && item.revisionCycle !== 100 ? item.revisionCycle : '-'),
                revisionLeft: (revisionSchedule && item.revisionCycle && item.revisionCycle !== 100 && revisionSchedule > item.revisionCycle) ? (revisionSchedule - item.revisionCycle) : "-",
                lastReviewed: (item.lastReviewed ? Utilities.timeSince(item.lastReviewed*1000, true) : '-'),
                nextReview: (item.reviewAfter ? Utilities.inDays(new Date(item.reviewAfter*1000)) : '-'),
            };

            this.setState({ stats: stats })
        }
    }

    render() {

        let open = this.props.open;
        let item = this.state.flashcard;

        if (!item) {
            return null;
        }

        if (item.exampleCount > 1 && item.exampleCount !== item.examples.length) {
            while (item.examples.length < item.exampleCount) {
                item.examples.push({
                    rel: 5,
                    term: Utilities.randomPreviewTerm(),
                    definition: Utilities.randomPreviewTerm(),
                    sample: true
                })
            }
        }

        return (
            <SwipeableDrawer
               anchor="bottom"
               open={open !== null && open !== undefined && open !== ""}
               key="flashcard-editor"
               onClose={ () => {
                    Utilities.onDrawerHide();
                    this.props.onClose(null);
               } }
               onOpen={ () => {
                   Utilities.onDrawerShow();
               } }
           >
                { (open !== null && open !== undefined && open !== "") &&
                   <div
                        data-keyboard-showing={this.state.keyboardShowing} 
                        ref={this.modalRef} 
                        data-drawer="flashcard-editor" 
                        className="flashcard-editor-modal"
                    >
                        <div className="drag-indicator"></div>
                            <div className="drag-close-indicator" onClick={() => {
                            Utilities.onDrawerHide();
                            this.props.onClose(null);
                        }}>
                            <IonIcon icon={closeCircle} />
                        </div>
                       <section className="edit">
                            <div className="quicks">
                                <div className="left">
                                    <div 
                                        className="strength"
                                        style={{
                                            zIndex: this.state.showGuide ? 100000 : undefined,
                                            position: this.state.showGuide ? 'relative' : undefined,
                                            backgroundColor: this.state.showGuide ? ( window.dark ? '#222' : 'white' ) : undefined,
                                            borderRadius: this.state.showGuide ? 8 : 0
                                        }}
                                    >
                                        <div className="tile" data-selected={item.strength === 1} onClick={() => { this.setStrength(1) }}>
                                            <IonIcon style={{ color: "#00CC9A" }} icon={playCircle} />
                                            <label>{window.ln.studying}</label>
                                        </div>
                                        <div className="tile" data-selected={item.strength === 3} onClick={() => { this.setStrength(3) }}>
                                            <IonIcon style={{ color: "#4D80E4" }} icon={checkmarkCircle} />
                                            <label>{window.ln.learnt}</label>
                                        </div>
                                        <div className="tile" data-selected={item.strength === 5} onClick={() => { this.setStrength(5) }}>
                                            <IonIcon style={{ color: "#9852f9" }} icon={checkmarkDoneCircle} />
                                            <label>{window.ln.mastered}</label>
                                        </div>
                                    </div>
                                </div>
                                <button 
                                    className="star"
                                    data-selected={item.star === "t"} 
                                    onClick={() => { 
                                        this.toggleStar();
                                    }}
                                    style={{
                                        zIndex: this.state.showGuide ? 100000 : undefined,
                                        position: this.state.showGuide ? 'relative' : undefined
                                    }}
                                >
                                    <div>
                                        <IonIcon style={{ color: item.star !== "t" && window.dark ? "white" : undefined }} icon={star} />
                                    </div>
                                </button>
                            </div>
                            <div className='word'>
                                <div className="term">
                                    {item.term}
                                </div>
                                <div className="definition">
                                    {item.definition}
                                </div>
                            </div>
                            { (this.state.flashcard.examples.length !== 0) &&
                                <div className='examples'>
                                    { this.state.flashcard.examples.map((example, exampleI) => {
                                        return (
                                            <div
                                                className='example'
                                                onClick={() => {
                                                    if (example.sample && window.activated) {
                                                        localStorage.setItem("downloadLatestOnSettingsEnter", "true");
                                                        window.location = "/settings";
                                                    } else if (!window.activated && example.sample) {
                                                        window.showSubscriptionModal("examples");
                                                    } else {
                                                        this.pronounceWord(example.term, example.definition)
                                                    }
                                                }}
                                            >
                                                <div>
                                                    <div style={{
                                                        filter: (example.sample) ? 'blur(3px)' : undefined
                                                    }}>{example.term}</div>
                                                    <div style={{
                                                        filter: (example.sample) ? 'blur(3px)' : undefined
                                                    }}>{example.definition}</div>
                                                </div>
                                                <IonIcon icon={volumeHigh} />
                                            </div>
                                        )
                                    }) }
                                </div>
                            }
                            { (item.tags.length !== 0) &&
                                <div className="tags">
                                    { item.tags.map(tagId => {
                                        const tag = window.tagReferences[tagId];
                                        if (tag.id === "star" || tag.id === "mastered" || tag.id === "new" || tag.id === "learnt" || tag.id === "studying") return null;
                                        return ( <IonChip 
                                            key={tag.id}
                                            data-color={
                                                ["a1", "a2", "b1", "b2"].indexOf(tagId) !== -1 ? "2" : 
                                                ['t1', 't2', 't3', 't4', 't5', 't6', 't7', 't8', 't9', 't10', 't11', 't12', 't13', 't14', 't15', 't16', 't17', 't18', 't19', 't20', 't21', 't22', 't23', 't24', 't25', 't26', 't27', 't28', 't29', 't30'].indexOf(tagId) !== -1 ? "3" :
                                                ['adj', 'verb', 'noun', 'adverb', 'prep', 'conj', 'inter', 'pron'].indexOf(tagId) !== -1 ? "1" :
                                                "6"
                                            }
                                        > 
                                            <IonLabel>{tag.name}</IonLabel>
                                        </IonChip> )
                                    }) }
                                </div>
                            }
                            <button className='report-issue-btn' onClick={() => {
                                window.open(window.INSTANCE_CONFIG.EMAIL + "?subject=Incorrect Word [" + window.INSTANCE_CONFIG.LANGUAGE_PAIR + "-" + item.id + "]&body=Please describe the issue here: ");
                            }}>{window.ln.reportIssue}</button>
                       </section>
                   </div>
               }
           </SwipeableDrawer>
        );
    }

};


export default withIonLifeCycle(FlashcardEditModal);
