import { Plugins } from '@capacitor/core';
import { IonChip, IonIcon, IonLabel, withIonLifeCycle } from '@ionic/react';
import { caretUpCircle, checkmarkCircle, checkmarkDoneCircle, create, eye, playCircle, reloadCircle, star, volumeHigh } from 'ionicons/icons';
import React from 'react';
import Utilities from '../Utilities';
import LibraryItem from './LibraryItem';

const { Browser } = Plugins;

class Flashcard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async pronounceWord(term, definition) {

        if ( (!window.activated) && (!Utilities.withinFreeTrial("pronunciation")) ) {
            window.showSubscriptionModal("pronunciation")
            return;
        }

        if (!window.canPronounceTerm && !window.canPronounceDefinition) {
            window.toast(window.ln.unsupportedLangP, "error");
            return;
        }

        this.setState({ pronouncing: true })

        if (window.canPronounceTerm && term)
            await Utilities.pronounceWord(term, window.INSTANCE_CONFIG.LANGUAGE_PACK_TERM);

        if (window.canPronounceDefinition && definition)
            await Utilities.pronounceWord(definition, window.INSTANCE_CONFIG.LANGUAGE_PACK_DEFINITION);

        this.setState({ pronouncing: false })

    }

    render() {

        const fontSize = !this.props.fontSize || this.props.fontSize === "3" ? "9vmin" : this.props.fontSize === "2" ? "7vmin" : this.props.fontSize === "1" ? "5vmin": this.props.fontSize === "4" ? "14vmin" : "20vmin"
        const item = this.props.flashcard;

        const contentDOM =                 
        <React.Fragment>
            { (this.props.setName) &&
                <IonChip> <IonLabel>{window.ln.foundIn.replace("__", this.props.setName)}</IonLabel> </IonChip>
            }
            { (this.props.altChip) &&
                <IonChip> <IonLabel>{this.props.altChip}</IonLabel> </IonChip>
            }
            { (this.props.altBtn) &&
                <IonChip
                    style={{
                        background: "#fa4252",
                        float: "right",
                        marginRight: 0
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.altBtnClick();
                    }}
                > <IonLabel>{this.props.altBtn}</IonLabel> </IonChip>
            }
            <div 
                className="options clearfix" 
                data-editing={item.editing} 
                onClick={(e) => {
                    if (this.props.view === "flashcard") {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.onClick();
                    }
                }}
                style={ (this.props.view === "flashcard") ? { textAlign: "right" } : {} }
            >
                <span className="strength">
                    <div data-color={item.strength || "10"} className="circle">
                        <IonIcon style={{ fontSize: 20 }} className="strength-icon" icon={item.strength === 1 ? playCircle : (item.strength === 3) ? checkmarkCircle : (item.strength === 5) ? checkmarkDoneCircle : caretUpCircle} />
                    </div>
                    <label>{item.strength === 1 ? window.ln.studying : (item.strength === 3) ? window.ln.learnt : (item.strength === 5) ? window.ln.mastered : window.ln.new}</label>
                </span>
                <span className="review-cycle">
                    <div data-color="0" className="circle">
                        <IonIcon className="review-icon" icon={reloadCircle} />
                    </div>
                    <label>{ (item.revisionCycle && item.revisionCycle < 100 && item.revisionCycle > 0) ? item.revisionCycle : "-"}</label>
                </span> 
                <span className="review-count">
                    <div data-color="0" className="circle">
                        <IonIcon className="review-icon" icon={eye} />
                    </div>
                    <label>{item.reviewCount || "0"}</label>
                </span> 
                { (this.props.view === "flashcard" && item.star) &&
                    <span className="review-count">
                        <div data-color="0" className="circle">
                            <IonIcon className="starred star-icon" icon={star} />
                        </div>
                    </span> 
                }
                { (this.props.view === "flashcard") &&
                    <span className="review-count">
                        <div data-color="0" className="circle">
                        </div>
                    </span> 
                }
                { (this.props.view !== "flashcard" && this.props.view !== "minimal") &&
                    <div className='right'>
                        { ((window.canPronounceTerm && item.term) || (window.canPronounceDefinition && item.definition)) &&
                            <IonIcon
                                className="pronounce-icon"
                                icon={volumeHigh}
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.pronounceWord(item.term, item.definition); }}
                            />
                        }
                        <IonIcon className={(item.star ? "starred" : "") + " star-icon"} icon={star} />
                    </div>
                }
                { ( (this.props.side === "front" || this.props.side === "back") && this.props.totalI ) &&
                    <div className='left'>
                        <div className='nth'>
                            <span>{this.props.i+1}</span>
                            <span>/</span>
                            <span>{this.props.totalI}</span>
                        </div>
                    </div>
                }
            </div>
            { (!this.props.side || this.props.side === "front") && <p style={(this.props.view === "flashcard" ? { alignSelf: 'center', fontSize: fontSize } : { })}>{item.term || "✘ " + window.ln.noTerm}</p> }
            { (!this.props.side || this.props.side === "back") && <p style={(this.props.view === "flashcard" ? { alignSelf: 'center', fontSize: fontSize } : { })}>{(item.definition || "✘ " + window.ln.noDef)}</p> }

            { ((!this.props.side || this.props.side === "front") && (item.examples[0] || item.exampleCount > 0)) && <p style={(this.props.view === "flashcard" ? { alignSelf: 'center', fontSize: fontSize } : { })}>
                {item.examples[0] ? item.examples[0].term : null}
                { (this.props.view === "flashcard" && (item.examples.length > 1 || item.exampleCount > 1)) && <div onClick={(e) => {
                    e.stopPropagation(); e.preventDefault(); this.props.onClick();
                }} className='badge-circle badge-rect'>{item.exampleCount || item.examples.length}+ examples</div> }
            </p> }
            { ((!this.props.side || this.props.side === "back") && (item.examples[0] || item.exampleCount > 0)) && <p style={(this.props.view === "flashcard" ? { alignSelf: 'center', fontSize: fontSize } : { })}>
                {item.examples[0] ? item.examples[0].definition : null}
                { (this.props.view === "flashcard" && (item.examples.length > 1 || item.exampleCount > 1)) && <div onClick={(e) => {
                    e.stopPropagation(); e.preventDefault(); this.props.onClick();
                }} className='badge-circle badge-rect'>{item.exampleCount || item.examples.length}+ examples</div> }
            </p> }
            
            { (this.props.view === "flashcard") && 
                <div className='dummy-buttons'>
                    
                </div>
            }
            { ( (this.props.view !== "flashcard") && ( ( (item.tags) && (item.tags.length !== 0) ) ) ) &&
                <div className="tags">
                    { item.tags.map(tagId => {
                        const tag = window.tagReferences[tagId];
                        if (tag.id === "star" || tag.id === "mastered" || tag.id === "new" || tag.id === "learnt" || tag.id === "studying") return null;
                        return ( 
                            <IonChip 
                                key={tag.id}
                                data-id={tag.id}
                                data-color={
                                    ["a1", "a2", "b1", "b2"].indexOf(tagId) !== -1 ? "2" : 
                                    ['t1', 't2', 't3', 't4', 't5', 't6', 't7', 't8', 't9', 't10', 't11', 't12', 't13', 't14', 't15', 't16', 't17', 't18', 't19', 't20', 't21', 't22', 't23', 't24', 't25', 't26', 't27', 't28', 't29', 't30'].indexOf(tagId) !== -1 ? "3" :
                                    ['adj', 'verb', 'noun', 'adverb', 'prep', 'conj', 'inter', 'pron'].indexOf(tagId) !== -1 ? "1" :
                                    "6"
                                }
                            >
                                <IonLabel>
                                    {window.INSTANCE_CONFIG.TAG_NAMES[tagId]}
                                </IonLabel>
                            </IonChip> )
                    }) }
                </div>
            }
        </React.Fragment>

        if (this.props.view === "flashcard") {
            return (
                <div
                    data-view={this.props.view} 
                    data-id={item.id} 
                    className="flashcard ioncard clearfix" 
                    style={{
                        overflow: 'hidden'
                    }}
                    onClick={() => {
                        this.props.onFlip(); 
                    }
                }>
                    {contentDOM}
                </div>
            );
        } else {
            return (
                <div 
                    data-view={this.props.view} 
                    data-id={item.id} 
                    className="flashcard ioncard clearfix"
                    data-selected={this.props.selected}
                    onClick={() => { 
                        Utilities.onDrawerShow();
                        this.props.onClick();
                    }}
                >
                    {contentDOM}
                </div>
            );
        }


    }

};


export default withIonLifeCycle(Flashcard);
