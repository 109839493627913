import { Plugins } from '@capacitor/core';
import { IonChip, IonIcon, IonLabel, withIonLifeCycle } from '@ionic/react';
import { barcodeOutline, caretUpCircle, checkmarkCircle, checkmarkDoneCircle, cloudDownloadOutline, create, eye, eyeOutline, playCircle, reloadCircle, star, textOutline, thumbsUpOutline, volumeHigh } from 'ionicons/icons';
import React from 'react';
import Utilities from '../Utilities';
import '../styles/LibraryItem.css';

const LibraryItemType = {
    Book: 1,
    Article: 2,
    YouTubeVideo: 3,
    SpotifyTrack: 4,
}

const { Browser } = Plugins;

class LibraryItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        if (!this.props.item)
            return null;

        const item = this.props.item;
        const parent = this.props.parent || {};

        const STATS_DOM = null // TODO restore
            // <div className='stats'>
            //     <span>
            //         <IonIcon icon={cloudDownloadOutline} />
            //     </span>
            //     <span>
            //         <IonIcon icon={eyeOutline} />
            //         34
            //     </span>
            //     <span>
            //         <IonIcon icon={thumbsUpOutline} />
            //         34
            //     </span>
            //     <span>
            //         <IonIcon icon={textOutline} />
            //         343
            //     </span>
            // </div>
                
        return (
            <div 
                className='library-item' 
                data-type={item.type}
                onClick={() => {
                    this.props.onClick(item, parent)
                }}
            >
                { (item.type === LibraryItemType.Article) && 
                    <div>
                        <div className='image' style={{ flexBasis: 52 }}>
                            <img src={item.thumbnailSmall || item.thumbnail || parent.logoUrl} />
                        </div>
                        <div className='meta' style={{ flexBasis: 'calc(100% - 52px)' }}>
                            <div className='title'>
                                {item.title}
                            </div>
                            {STATS_DOM}
                        </div>
                    </div>
                }
                { (item.type === LibraryItemType.YouTubeVideo) && 
                    <div>
                        <div className='image' style={{ flexBasis: 80 }}>
                            <img src={item.thumbnailSmall || item.thumbnail || parent.logoUrl} />
                        </div>
                        <div className='meta' style={{ flexBasis: 'calc(100% - 80px)' }}>
                            <div className='title'>
                                {item.title}
                            </div>
                            {STATS_DOM}
                        </div>
                    </div>
                }
                { (item.type === 4 || item.type === 5 || item.type === LibraryItemType.SpotifyTrack) && 
                    <div>
                        <div className='image' style={{ flexBasis: 52 }}>
                            <img src={item.thumbnailSmall || item.thumbnail || parent.logoUrl} />
                        </div>
                        <div className='meta' style={{ flexBasis: 'calc(100% - 52px)' }}>
                            <div className='title'>
                                {item.title}
                            </div>
                            {STATS_DOM}
                        </div>
                    </div>
                }
            </div>
        )
    }

};


export default withIonLifeCycle(LibraryItem);
