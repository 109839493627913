import { Plugins } from '@capacitor/core';
import { IonIcon } from '@ionic/react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import CountTo from 'react-count-to';
import '../styles/ReviewModal.scss';
import Utilities from '../Utilities';

import { closeCircle, eye, ribbon, rocket, share, starHalf } from 'ionicons/icons';

import Image1 from '../images/practice-end/1.gif';
import Image2 from '../images/practice-end/2.gif';
import Image3 from '../images/practice-end/3.gif';
import Image4 from '../images/practice-end/4.gif';
import Image5 from '../images/practice-end/5.gif';
import Image6 from '../images/practice-end/6.gif';
import Image7 from '../images/practice-end/7.gif';

const { Share, Clipboard } = Plugins;

const IMAGES = [Image1, Image2, Image3, Image4, Image5, Image6, Image7]

class ReviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            hasLeveledUp: false,
            xp: null,
            goalWidth: 0,
            levelWidth: 0,
            activeDayStreak: 0,
            randomGifI: null
        }
    }

    async componentDidMount() {

    }

    async share() {

        // TO DO maybe multi image, with flashcard, all stats, flags etc...

        const text = "I have reviewed " + (this.props.data.after.reviews) + " flashcards in total with the " + window.INSTANCE_CONFIG.APP_NAME + " app. Download Now for free to study anything, anytime, anywhere, anyhow. https://bit.ly/cnc-3000"

        Utilities.trackEvent("Share", "Recommend App", "Review Modal")

        if (window.os === "web") {

            Clipboard.write({
                string: text
            });

            setTimeout(() => {
                window.toast(window.ln.copied);
            }, 200)

        } else {

            let shareRet = await Share.share({
                title: window.INSTANCE_CONFIG.APP_NAME,
                text: text,
                url: "https://bit.ly/cnc-3000",
                dialogTitle: text,
            });

        }

    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.data && !this.props.data) {
            Utilities.onDrawerShow();
            this.setState({
                title: Utilities.wellDone(),
                activeDayStreak: nextProps.data.after.activeDayStreak,
                randomGifI: Utilities.randomIntFromInterval(0, IMAGES.length - 1)
            }, () => {
                // Utilities.trackEvent("Practice", "Practice Statistics Show", (this.props.data.after.reviews - this.props.data.before.reviews) + "|" + ( this.props.data && this.props.data.after && this.props.data.before ? this.props.data.after.learnt - this.props.data.before.learnt : 0) + "|" + (this.props.data && this.props.data.after && this.props.data.before ? this.props.data.after.mastered - this.props.data.before.mastered : 0) + "|" + afterXp, this.props.data.after.reviews - this.props.data.before.reviews)
                // setTimeout(() => {
                //     const el = document.getElementById('vid-' + this.state.randomGifI)
                //     if (el && !el.playing) {
                //         el.play();   
                //     }
                // }, 300)
            })
        }
    }

    render() {

        const newLearnt = this.props.data && this.props.data.after && this.props.data.before ? this.props.data.after.learnt - this.props.data.before.learnt : 0;
        const newMastered = this.props.data && this.props.data.after && this.props.data.before ? this.props.data.after.mastered - this.props.data.before.mastered : 0;

        return (
        <SwipeableDrawer
            anchor="bottom"
            open={this.props.data !== null}
            key="flashcard-editor"
            transitionDuration={0}
            className="type-selector"
            onOpen={ () => {
                Utilities.onDrawerShow();
            } }
            onClose={ () => { 
                Utilities.onDrawerHide(); this.props.onClose();
            } }
        >
            <div 
                ref={this.modalRef}
                className="practice-end-modal" 
                style={{ 
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    padding: 15,
                    background: "white",
                    borderRadius: '32px 32px 0 0'
                }} 
                id="share-mdl"
            >
                <div className="drag-indicator"></div>
                <h2>
                    {this.state.title}
                </h2>
                <div className="drag-close-indicator" onClick={() => {
                    Utilities.onDrawerHide();
                    this.props.onClose();
                }}>
                    <IonIcon icon={closeCircle} />
                </div>
                <div className='body'>
                    { this.props.data &&
                        <React.Fragment>
                        <div className='highlights'>
                            <div className='stat'>
                                <IonIcon icon={eye} />
                                <div className='total'>
                                    <CountTo from={0} to={this.props.data.after.reviews} speed={1000}>{(value) => <span>{value}</span>}</CountTo>
                                </div>
                                <p>{window.ln.reviews}</p>
                                <div className='new'>
                                    <CountTo to={this.props.data.after.reviews - this.props.data.before.reviews} speed={1000}>{(value) => <span>{value}</span>}</CountTo> <span>{window.ln.new}</span>
                                </div>
                            </div>

                            <div className='stat'>
                                <IonIcon icon={ribbon} />
                                <div className='total'>
                                    <CountTo from={0} to={this.props.data.after.learnt} speed={1000}>{(value) => <span>{value}</span>}</CountTo>
                                </div>
                                <p>{window.ln.learnt}</p>
                                <div className='new'>
                                    <CountTo to={newLearnt > 0 ? newLearnt : 0} speed={1000}>{(value) => <span>{value}</span>}</CountTo> <span>{window.ln.new}</span>
                                </div>
                            </div>

                            <div className='stat'>
                                <IonIcon icon={rocket} />
                                <div className='total'>
                                    <CountTo from={0} to={this.props.data.after.mastered} speed={1000}>{(value) => <span>{value}</span>}</CountTo>
                                </div>
                                <p>{window.ln.mastered}</p>
                                <div className='new'>
                                    <CountTo to={newMastered > 0 ? newMastered : 0} speed={1000}>{(value) => <span>{value}</span>}</CountTo> <span>{window.ln.new}</span>
                                </div>
                            </div>

                        </div> 
                        </React.Fragment>
                    }
                    { (this.state.randomGifI !== null) &&
                        <div className='gif'>
                            <img src={IMAGES[this.state.randomGifI]} />
                        </div>
                    }
                    <div className='opts'>
                        <button onClick={() => {
                            Utilities.trackEvent("Rate", window.os === "android" ? "Open Google Play" : "Open AppStore", "Review Modal")
                            window.open(window.os === "android" ? ("https://play.google.com/store/apps/details?id=" + window.INSTANCE_CONFIG.PACKAGE_NAME) : "https://itunes.apple.com/gb/app/" + window.INSTANCE_CONFIG.APPLE_ID)
                        }}>
                            <IonIcon icon={starHalf} />
                            <span>{window.ln.rateApp}</span>
                        </button>
                        <button onClick={() => { this.share(); }}>
                            <IonIcon icon={share} />
                            <span>{window.ln.share}</span>
                        </button>
                    </div>
                </div>
            </div>
        </SwipeableDrawer>)
    }

};

export default ReviewModal;
