import { Plugins } from '@capacitor/core';
import { IonIcon, IonToast, withIonLifeCycle } from '@ionic/react';
import { chatbubbleEllipses, logoAppleAppstore, logoGooglePlaystore, mailOpen, share } from 'ionicons/icons';
import React from 'react';
import '../styles/ContactUs.scss';
import Utilities from '../Utilities';
const { Share, Clipboard } = Plugins;

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: null
        }
    }

    render() {
        return (
            <div className="contact-us">
                <p id="p">{window.ln.contactUsTitle}</p>
                <div className="options">
                    <button onClick={() => { 
                        Utilities.trackEvent("Contact", "Contact by Email", "Contact Us")
                        window.open(window.INSTANCE_CONFIG.EMAIL)
                    }} >
                        <div className='circle blue-circle'>
                            <IonIcon icon={mailOpen} />
                        </div>
                        <span>{window.ln.emailUs}</span>
                    </button>
                    <button onClick={() => {
                        Utilities.trackEvent("Contact", "Contact by WhatsApp", "Contact Us")
                        window.open(window.INSTANCE_CONFIG.WA)
                    }} >
                        <div className='circle blue-circle'>
                            <IonIcon className="guide" data-guide-contact="1" icon={chatbubbleEllipses} />
                        </div>
                        <span>{window.ln.whatsappUs}</span>
                    </button>
                    { (window.os !== "android") &&
                        <button className="guide" data-guide-rate="1" onClick={() => {
                            Utilities.trackEvent("Rate", "Open AppStore", "Contact Us")
                            window.open("https://itunes.apple.com/gb/app/" + window.INSTANCE_CONFIG.APPLE_ID)
                        }}>
                            <div className='circle blue-circle'>
                                <IonIcon icon={logoAppleAppstore} />
                            </div>
                            <span>{window.ln.rateAppStore}</span>
                        </button>
                    }
                    { (window.os === "android") &&
                        <button className="guide" data-guide-rate="1" onClick={() => {
                            Utilities.trackEvent("Rate", "Open Google Play", "Contact Us")
                            window.open("https://play.google.com/store/apps/details?id=" + window.INSTANCE_CONFIG.PACKAGE_NAME)
                        }}>
                            <div className='circle blue-circle'>
                                <IonIcon icon={logoGooglePlaystore} />
                            </div>
                            <span>{window.ln.rateGoogle}</span>
                        </button>
                    }
                    <button onClick={ async () => {

                        Utilities.trackEvent("Share", "Recommend App", "Contact Us")

                        const linkToApp = window.INSTANCE_CONFIG.OTHER_LANGS_LINK;
                        const promoStr = 'Check out the ' + window.INSTANCE_CONFIG.APP_NAME + ' app I have been using. ';

                        if (window.os === "web") {

                            Clipboard.write({
                                string: promoStr + linkToApp
                            });

                            setTimeout(() => {
                                window.toast(window.ln.copied);
                            }, 200)

                        } else {

                            let shareRet = await Share.share({
                                title: window.INSTANCE_CONFIG.APP_NAME,
                                text: promoStr + linkToApp,
                                url: linkToApp,
                                dialogTitle: promoStr + linkToApp,
                            });

                        }

                    }}>
                        <div className='circle blue-circle'>
                            <IonIcon icon={share} />
                        </div>
                        <span>{window.ln.shareFriends}</span>
                    </button>
                </div>
                <IonToast
                    isOpen={this.state.message !== null}
                    onDidDismiss={() => { this.setState({ message: null }) }}
                    message={this.state.message}
                    position="top"
                    duration={2000}
                    buttons={[ { text: window.ln.close } ]} 
                    color="dark"
                />
            </div>
        );
    }

};


export default withIonLifeCycle(ContactUs);
