import axios from 'axios';
import SQLStorage from './SQLStorage';
import Server from './Server';
import Storage from './Storage';
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import ReactGA from "react-ga4";
window.gaInited = false;
window.lastTrackedEventHash = "";

const IMAGE_PLACEHOLDER = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADeUExURUdwTA2njUtXb0pYbkpWbhKdiyCfnxiViAWvkSOLgEtYb1VVakpVblVVgE1Nc0tXcElUbUBgYAC1lEpWbv/MA0Fhcj5ldAG0lDxodTxndUBjc0hYb0lXbgOxkwOxkkpXbjlsdzdveENfcgKykzF2ewatkVBaaklXb8qqIjVxecqpIpWHQv7MA0NgcktXbrmfLEFicwGzkz5mdD1mdFZdZ1VdZ2RnXtq0GPnIBsCjKJqKPz1ndWlqXK+YMjhtd4qASUhZb9SwHdOwHQKzk6+YMwaukQWukUVdcURdcUVccRr21jgAAAASdFJOUwBiVV3ttgh0/RZODKUGFFKICE/keqMAAAGgSURBVFjD7dnHUsNAEARQGRzJtIIlgcHknHPO6f9/CAtLsiSvwtizVVBsn7te9WX3MpoWZERnyLSWTFlnSjnGzuqMmey5ozprxgJ3SmfOuA/r7JHl+rIseEIGPCNp8M9kBStYwf8PPr3b3pEAHxjdnDHDRphXVtiI5JwRfojCBiMcc41rWbDx++H1P7c4AT/xwZt5g7HK8EAuBC6wNfSTvhS6wP2An9Cu716luEB7wG9z/7nD3uqpLrDE+9EDafJwMKJpF4JtsgvcFIAtgOwCx7mw6dXILmDlwGa3RnaBk0zY8ltNshvZLIDtsNUku8BhKuxEWk2yC5gpsBtrieVFZGVPCJuJVpO418uyAHb7Wi/EvYEchx1Bq5Vw55GfzwRsC1stsgt8xWArpbUScTdQLB8R2ExtHRH3eunBZkZrwe/MgQ5bmbV3qhvCdk5vrdN5BB12cotvpL0B7BZoLoAOm+CPB7uQAzuQBEPBClawghWs4IHgigy3okmarEmFNVmu1uB2G8FBr8br1nonyCqnW41dTetcbL3vzlviYEsh9w1cjSJlU/nAMgAAAABJRU5ErkJggg=="
const { Device, LocalNotifications, Filesystem } = Plugins;

const DEAFULT_SPACE_REP = [
    { hours: 24 }, // 1 day -> 1st day
    { hours: 48 }, // 2 days -> 3rd day
    { hours: 96, learnt: true }, // 4 days -> 7th day
    { hours: 192 }, // 8 days -> 15th day
    { hours: 384, mastered: true } // 16 days -> 31st day
]

const REVIEW_XP = 1;
const LEARNT_XP = 3;
const MASTERED_XP = 6;

class Utilities {

    static async logout() {
        localStorage.clear();
        await window.db.delete();
    }

    static removeQuerySelectors() {
        if (document.querySelector("[name='description']")) document.querySelector("[name='description']").remove();
        if (document.querySelector("[property='og:type']")) document.querySelector("[property='og:type']").remove();
        if (document.querySelector("[property='og:locale']")) document.querySelector("[property='og:locale']").remove();
        if (document.querySelector("[property='og:site_name']")) document.querySelector("[property='og:site_name']").remove();
        if (document.querySelector("[property='og:title']")) document.querySelector("[property='og:title']").remove();
        if (document.querySelector("[property='og:url']")) document.querySelector("[property='og:url']").remove();
        if (document.querySelector("[property='og:description']")) document.querySelector("[property='og:description']").remove();
        if (document.querySelector("[property='og:image']")) document.querySelector("[property='og:image']").remove();
        if (document.querySelector("[name='twitter:title']")) document.querySelector("[name='twitter:title']").remove();
        if (document.querySelector("[name='twitter:description']")) document.querySelector("[name='twitter:description']").remove();
        if (document.querySelector("[name='twitter:card']")) document.querySelector("[name='twitter:card']").remove();
        if (document.querySelector("[name='twitter:url']")) document.querySelector("[name='twitter:url']").remove();
        if (document.querySelector("[name='twitter:image']")) document.querySelector("[name='twitter:image']").remove();}

    static writeDefaultMetaTags() {
        console.log("========= WRITE DEFAULT META TAGS =============");
        Utilities.removeQuerySelectors();
        const DEFAULT_META_DESC = "Flashcards made Simple with sync, tagging, images, spaced repetition, notification, pronunciation, autoplay, and more";
        const DEFAULT_TITLE = "Flashcards made Simple"
        const DEFAULT_URL = "https://flashcardsmadesimple.com"
        const DEFAULT_IMAGE = "https://firebasestorage.googleapis.com/v0/b/simpleflashcardmanager.appspot.com/o/website%2Fcover-min.png?alt=media&token=f8b2b84b-14a1-43cf-aaba-13b482e54577"

        document.title = DEFAULT_TITLE;
        let link = document.createElement('meta'); link.setAttribute('name', 'description');  link.content = DEFAULT_META_DESC; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:type');  link.content = "website"; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:locale');  link.content = "en_US"; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:site_name');  link.content = DEFAULT_TITLE; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:title');  link.content = DEFAULT_TITLE; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:url');  link.content = DEFAULT_URL; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:description');  link.content = DEFAULT_META_DESC; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('property', 'og:image');  link.content = DEFAULT_IMAGE; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('name', 'twitter:title');  link.content = document.title; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('name', 'twitter:description');  link.content = DEFAULT_META_DESC; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('name', 'twitter:card');  link.content = "summary_large_image"; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('name', 'twitter:url');  link.content = DEFAULT_URL; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('name', 'twitter:title');  link.content = document.title; document.getElementsByTagName('head')[0].appendChild(link);
        link = document.createElement('meta'); link.setAttribute('name', 'twitter:image');  link.content = DEFAULT_IMAGE; document.getElementsByTagName('head')[0].appendChild(link);
    }

    static writeCustomMetaTags() {
        Utilities.removeQuerySelectors();
        console.log("========= CUSTOM META TAGS WRITING =============");
    }

    static async trackEvent(category, action, label, value, nonInteraction) {
        return; // TODO remove
        if (window.location.href.indexOf("localhost:8100") !== -1) {
            return;
        }

        if (!window.gaInited) {
            ReactGA.initialize("G-DW6T0GTH8Z");
            window.gaInited = true;
        }

        if (window.INSTANCE_CONFIG.LANGUAGE_PAIR) {
            category = "[" + window.INSTANCE_CONFIG.LANGUAGE_PAIR + "] " + category
        }

        const hash = category + "_" + action + "_" + label + "_" + value;

        console.log("TRACKING: " + hash)

        if (hash === window.lastTrackedEventHash) {
            console.log("DROPPED BECAUSE DUPE _ EVENT TRACKED");
            return;
        }

        if (window.preventFocusBlurTrack && (action === "Blur" || action === "Focus")) {
            console.log("DROPPED BECAUSE blur/focus while confirm _ EVENT TRACKED");
            return;
        }

        if (localStorage.getItem("preventAppOpenTrack") && action === "App Start") {
            localStorage.removeItem("preventAppOpenTrack")
            console.log("DROPPED BECAUSE blur/preventAppOpenTrack=true _ EVENT TRACKED");
            return 
        }

        window.lastTrackedEventHash = hash;
        clearTimeout(window.lastTrackedEventTimeout);
        window.lastTrackedEventTimeout = setTimeout(() => {
            window.lastTrackedEventHash = "";
        }, 400)

        console.log("EVENT TRACKED", {
            category: category,
            action: action,
            label: label,
            value: value !== undefined && value !== null ? parseInt(value) : undefined,
            nonInteraction: nonInteraction || false
        })

        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value !== undefined && value !== null ? parseInt(value) : undefined,
            nonInteraction: nonInteraction || false
        });
    }

    static async trackPageview() {
        if (!window.gaInited) {
            ReactGA.initialize("G-DW6T0GTH8Z");
            window.gaInited = true;
        }
        ReactGA.send("pageview");
    }

    static async saveCurrentStats() {
        let res = await this.getReviewEndStats(true);
        res = await Storage.setO("statsBeforePractice", res);
        return res;
    }

    static async getReviewEndStats(dataForPrePractice) {
        let start = new Date();
        let end = new Date();
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        let strengths = await SQLStorage.getStrengthCardsCount();
        let totalReviews = await SQLStorage.getTotalReviewsCount();
        let totalReviewedCardsToday = await SQLStorage.getCardsBetweenReviewed(start.getTime()/1000, end.getTime()/1000);
        const activeDays = !dataForPrePractice ? (await SQLStorage.getActivityCountGrouppedByDay()) : null;

        let activeDayStreak = 0;

        if (activeDays) {
            const dayHashes = activeDays.map(item => item.DateHash);
            
            let prevDay = Utilities.dateSub(new Date(), "day", 1);
            let prevDayHash = Utilities.formatDate(prevDay, "YYYY-MM-DD");
            while (dayHashes.indexOf(prevDayHash) !== -1) {
                // TO DO maybe goal achieved actually? - but then what if goal number changed
                activeDayStreak++;
                prevDay = Utilities.dateSub(prevDay, "day", 1);
                prevDayHash = Utilities.formatDate(prevDay, "YYYY-MM-DD");
            }
        }

        // TO DO Studyset Shared
        // TO DO Active Day Strek
        // TO DO langs EACh one separately
        const xp = strengths["3"] * LEARNT_XP + 
            (strengths["3"] + strengths["5"]) * MASTERED_XP +
            totalReviews;

        return {
            learnt: strengths["3"],
            mastered: strengths["5"],
            reviews: totalReviews,
            reviewedCardIdsToday: totalReviewedCardsToday.length,
            xp: xp,
            activeDayStreak: activeDayStreak
        }
    }

    static arrayEquals(a, b) {
        return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
    }

    static onDrawerShow() {
        document.body.classList.remove("backdrop-no-scroll");
        setTimeout(() => {
            document.body.classList.remove("backdrop-no-scroll");
        }, 500)
        if (window.notchSizeBottom) {
            setTimeout(() => { this.adjustBottomNotchMargins(); }, 50);
            setTimeout(() => { this.adjustBottomNotchMargins(); }, 150);
            setTimeout(() => { this.adjustBottomNotchMargins(); }, 300);
            setTimeout(() => { this.adjustBottomNotchMargins(); }, 600);
        }
    }

    static adjustBottomNotchMargins() {
        const drawers = document.querySelectorAll(".MuiDrawer-modal>div>div");
        for (let i = 0, len = drawers.length; i < len; i++) {
            drawers[i].style.paddingBottom = window.notchSizeBottom + "px"
        }
    }

    static onDrawerHide() {
        // document.body.classList.remove("backdrop-no-scroll");
    }

    static wellDone() {
        const TEXTS = [
            window.ln.wellDone,
            window.ln.keepItUp,
            window.ln.youRock,
            window.ln.niceOne
        ]
        const EMOJIS = ["😁", "😊", "😍", "😜", "😎", "🤓", "😎", "🤩", "🤗", "🥳", "🤘", "👏"];
        return TEXTS[Utilities.randomIntFromInterval(0, TEXTS.length - 1)] + " " + EMOJIS[Utilities.randomIntFromInterval(0, EMOJIS.length - 1)]
    }

    static async cancelPendingQueueNotifications() {
        let res = await LocalNotifications.cancel( { notifications: [{id: "0"}, {id: "1"}, {id: "2"}, {id: "3"}, {id: "4"}, {id: "5"}, {id: "6"}, {id: "7"}, {id: "8"}, {id: "9"}, {id: "10"}, {id: "11"}, {id: "12"}, {id: "13"}, {id: "14"}, {id: "15"}, {id: "16"}, {id: "17"}, {id: "18"}, {id: "19"}, {id: "20"}, {id: "21"}, {id: "22"}, {id: "23"}, {id: "24"}, {id: "25"}, {id: "26"}, {id: "27"}, {id: "28"}, {id: "29"}, {id: "30"}, {id: "31"}, {id: "32"}, {id: "33"}, {id: "34"}, {id: "35"}, {id: "36"}, {id: "37"}, {id: "38"}, {id: "39"}, {id: "40"}, {id: "41"}, {id: "42"}, {id: "43"}, {id: "44"}, {id: "45"}, {id: "46"}, {id: "47"}, {id: "48"}, {id: "49"}, {id: "50"}, {id: "51"}, {id: "52"}, {id: "53"}, {id: "54"}, {id: "55"}, {id: "56"}, {id: "100"}] } );
        return res;
    }

    static async cancelPendingCatchupNotifications() {
        let res = await LocalNotifications.cancel( { notifications: [{id: "101"}, {id: "102"}, {id: "103"}, {id: "104"}, {id: "105"}, {id: "106"}, {id: "107"}] } );
        return res;
    }

    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static startAndEndOfWeek(date) {

        date = new Date(date)

        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
        let start = new Date(date.setDate(diff));
        let end = this.dateAdd(start, "day", 6)

        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        return [start, end]
    
    }

    static async slack(str, appId) {
        let deviceInfo = await Device.getInfo();
        str = "[" + (appId || window.INSTANCE_CONFIG.LANGUAGE_PAIR) + "] " + str;
        axios.post("https://hooks.slack.com/services/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", JSON.stringify({ "text": deviceInfo.uuid + " _ " + str }), {
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data
            }]
        }).then(function() { }).catch(function(e) { console.error("Sent init ERR", e); })
    }

    static async substrCount(string, subString, allowOverlapping) {
        string += "";
        subString += "";
        if (subString.length <= 0) return (string.length + 1);
    
        var n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subString.length;
    
        while (true) {
            pos = string.indexOf(subString, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }

    static removeNonAlfa(str) {
        return str.replace(/[^a-z0-9A-Z ]/gi, '');
    }

    static toFixedNumber(num, toFixTo = 1, base = 10) {
        const pow = Math.pow(base, toFixTo)
        return +(Math.round(num * pow) / pow)
    }

    static withinFreeTrial(feature) {
        let useCount = localStorage.getItem(feature + "-trialcount") ? parseInt(localStorage.getItem(feature + "-trialcount")) : 0;
        useCount++;
        localStorage.setItem(feature + "-trialcount", useCount)
        return useCount < 11;
    }

    static stopPronounceWord() {
        if (speechSynthesis.speaking) {
            try { speechSynthesis.cancel(); } catch (e) { console.log(e)  }
        }
    }

    static pronounceWord(word, desiredLanguage, speed = 1) {

        // console.log("PR " + word + " in " + desiredLanguage)

        Utilities.trackEvent("Flashcard", "Flashcard Pronounce", word + "|" + desiredLanguage);

        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
            return setTimeout(() => {
                this.pronounceWord(word, desiredLanguage);
            }, 300)
        }

        return new Promise(function(resolve, reject) {

            // console.log("Attept to pronounce " + word + " in " + desiredLanguage);
            let startTime = new Date()
            let finishedPlaying = false;

            let voiceObj = null;
            let voices = speechSynthesis.getVoices();

            // console.log("voices>>>>>>>>>>>>>", voices, voices.filter(v => window.LANGUAGE_CODES[v.lang.substr(0, 2)] === desiredLanguage));

            const preferredVoice = window.settings.voiceOverride ? voices.find(v => v.voiceURI === window.settings.voiceOverride) : null;

            if (!preferredVoice) {
                for (let i = 0; i < voices.length; i++) {
                    let shortcode = voices[i].lang.substr(0, 2);
                    let language = window.LANGUAGE_CODES[shortcode]
                    if (language === desiredLanguage) {
                        voiceObj = voices[i];
                        break;
                    }
                }
            } else {
                voiceObj = preferredVoice
            }

            if (!voiceObj) {
                console.error("== Couldn't find voice");
                return resolve();
            }

            let msg = new SpeechSynthesisUtterance();

            msg.rate = speed;
            msg.pitch = 1;
            msg.voice = voiceObj;
            msg.text = word

            let stuckCheckInterval = setInterval(() => {
                if (finishedPlaying) {
                    clearInterval(stuckCheckInterval)
                } else if (!speechSynthesis.speaking || Utilities.differenceBetweenDatesSeconds(new Date(), startTime)/word.length > 0.43) {
                    try { speechSynthesis.cancel(); } catch (e) {  }
                    clearInterval(stuckCheckInterval)
                    resolve();
                }
            }, 500)

            msg.onend = function (event) {
                // console.log("finished pronouncing")
                finishedPlaying = true;
                clearInterval(stuckCheckInterval)
                resolve();
            };

            msg.onerror = function(e) {
                finishedPlaying = true;
                clearInterval(stuckCheckInterval)
                resolve()
            }

            if (window.onPronounciationBoundary) {
                msg.onboundary = function(b) {
                    window.onPronounciationBoundary(b)
                }
            }

            speechSynthesis.speak(msg);

        })

    }

    static getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    static timestamp() {
        return Math.round(((new Date()).getTime()/1000));
    }

    static getTimestamp(date) {
        return Math.round((new Date(date)).getTime()/1000);
    }

    static getId() {
        return Math.round((new Date()).getTime()) + "_" + this.randomStr(6, "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
    }

    static randomStr(length, altP) {

    	let text = "";
    	let possible = altP || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    	for (let i = 0; i < length; i++)
    		text += possible.charAt(Math.floor(Math.random() * possible.length));

    	return text;

    }

    static getDatesBetween = function(startDate, endDate) {
        var dates = [],
            currentDate = startDate,
            addDays = function(days) {
              var date = new Date(this.valueOf());
              date.setDate(date.getDate() + days);
              return date;
            };
        while (currentDate <= endDate) {
          dates.push(currentDate);
          currentDate = addDays.call(currentDate, 1);
        }
        return dates;
      };

    static dateSub(date, interval, units) {
        var ret = new Date(date); //don't change original date
        var checkRollover = function() { if(ret.getDate() != date.getDate()) ret.setDate(0);};
        switch(interval.toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() - units); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() - 3*units); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() - units); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() - 7*units);  break;
        case 'day'    :  ret.setDate(ret.getDate() - units);  break;
        case 'hour'   :  ret.setTime(ret.getTime() - units*3600000);  break;
        case 'minute' :  ret.setTime(ret.getTime() - units*60000);  break;
        case 'second' :  ret.setTime(ret.getTime() - units*1000);  break;
        default       :  ret = undefined;  break;
        }
        return ret;
    }

    static dateAdd(date, interval, units) {
        var ret = new Date(date); //don't change original date
        var checkRollover = function() { if(ret.getDate() != date.getDate()) ret.setDate(0);};
        switch(interval.toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
        case 'day'    :  ret.setDate(ret.getDate() + units);  break;
        case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
        case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
        case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
        default       :  ret = undefined;  break;
        }
        return ret;
    }

    static dynamicSort(property, sortOrderUser) {
        var sortOrder = sortOrderUser || 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    static resizeImageThumbnail(base64) {

        let quality = 0.85;
        let maxLongerDimension = 96;

        return new Promise(function(resolve, reject) {

            var canvas = document.createElement("canvas");
            var imgResize = document.createElement("img");

            imgResize.onerror = function(e) {
                console.log("Failed", e);
            }
        
            imgResize.onload = function() {
        
                var ctx = canvas.getContext("2d");
                ctx.drawImage(imgResize, 0, 0);
                var MAX_WIDTH = maxLongerDimension;
                var MAX_HEIGHT = maxLongerDimension;
                var width = imgResize.width;
                var height = imgResize.height;

                if (height > width) {
                    let ratio = maxLongerDimension / width
                    width = maxLongerDimension;
                    height = ratio * height
                } else {
                    let ratio = maxLongerDimension / height
                    height = maxLongerDimension;
                    width = ratio * width
                }

                canvas.width = parseInt(maxLongerDimension);
                canvas.height = parseInt(maxLongerDimension);
                ctx = canvas.getContext("2d");
                ctx.drawImage(imgResize, 0, 0, parseInt(width), parseInt(height));
        
                resolve(canvas.toDataURL("image/jpeg", quality));
        
            }
        
            imgResize.src = base64;

        })
    
    }

    static resizeImage(base64, maxLongerDimension = 1600) {

        // Thumbnail
        let quality = 0.9;

        return new Promise(function(resolve, reject) {

            var canvas = document.createElement("canvas");
            var imgResize = document.createElement("img");

            imgResize.onerror = function(e) {
                console.log("Failed", e);
            }
        
            imgResize.onload = function() {
        
                var ctx = canvas.getContext("2d");
                ctx.drawImage(imgResize, 0, 0);
                var MAX_WIDTH = maxLongerDimension;
                var MAX_HEIGHT = maxLongerDimension;
                var width = imgResize.width;
                var height = imgResize.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                      height *= MAX_WIDTH / width;
                      width = MAX_WIDTH;
                    }
                  } else {
                    if (height > MAX_HEIGHT) {
                      width *= MAX_HEIGHT / height;
                      height = MAX_HEIGHT;
                    }
                  }

                  canvas.width = parseInt(width);
                  canvas.height = parseInt(height);
                  ctx = canvas.getContext("2d");
                  ctx.drawImage(imgResize, 0, 0, parseInt(width), parseInt(height));
        
                resolve(canvas.toDataURL("image/jpeg", quality));
        
            }
        
            imgResize.src = base64;

        })
    
    }

    static escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static async getImage(cardId, link, thumbnail, rand, returnBase64, noCache) {

        console.log("%%%%% RAND: " + rand)

        let data;
        const begin = "data:image/jpeg;base64,";

        if (!window.base64ImageCache) {
            window.base64ImageCache = {};
        }

        // Only dropbox - TO DO update in future for other provides
        const fileName = link.split("/")[5].split("?")[0];
        let hash = (thumbnail ? "thumb-" : "image-") + (fileName.replace("image-image-", "").replace("image-", "").replace("thumb-", ""));

        console.log("%%%%% GETTING IMAGE " + hash);
        
        if (window.base64ImageCache[hash] === "getting") {
            console.log("%% Already getting this");
            return;
        } else if (window.base64ImageCache[hash]) {
            console.log("%% Already have this in cache this");
            data = window.base64ImageCache[hash];
        } else {
            window.base64ImageCache[hash] = "getting"
        }

        if (!data && link.startsWith(begin)) {
            console.log("%% already in base 64")
            data = link;
        }

        if (!data && window.os !== "web" && window.cachedImages.indexOf(hash) !== -1) {

            console.log("%% Trying to get from Storage")

            let uri = await Filesystem.getUri({ directory: FilesystemDirectory.Data, path: hash + ".txt" });

            if (uri && uri.uri) {
                data = await Filesystem.readFile({ path: uri.uri }).catch(e => { console.log("File not found", e) });
                if (data && data.data) {
                    data = data.data;
                }
            }

        }
        
        if (!data) {

            // console.log("%% Trying to download from Dropbox")

            // Dropbox, TO DO other platforms
            try {
                data = await window.dropbox.downloadRawFile(fileName).catch(e => { console.log("Failed to get", e) });
                if (data) {
                    data = data.replace("data:application/octet-stream;base64,", begin)
                }
            } catch (e) {
                console.error(e)
            }

            console.log("%% DOWNLOADED IMAGE from dropbox")

            // data = Utilities.randomIntFromInterval(0, 1) === 0 ? data : null

            if (data && window.os !== "web" && !noCache && window.cachedImages.indexOf(hash) === -1) {
                let res = await Filesystem.writeFile({ path: hash + ".txt", data, directory: FilesystemDirectory.Data }).catch(e => { console.error("%% Failed to write file", e); })
                console.log(res, "%% Written file")
                window.cachedImages.push(hash);
                if (window.cachedImagesToLocalStorageTimeout) {
                    clearTimeout(window.cachedImagesToLocalStorageTimeout);
                }
                window.cachedImagesToLocalStorageTimeout = setTimeout(() => {
                    console.log("+=============  SAVING CACHE TO LOCAL STOAGGEGEOIJGEOIJTOIE")
                    localStorage.setItem("cachedImages", JSON.stringify(window.cachedImages))
                }, 1000);
            }

            if (!data) {
                console.log("%%% SIMULATED DOWNLOAD FAIL!!!!! ")
                // window.base64ImageCache[hash] = undefined;
            }

        }

        if (data) {

            if (!data.startsWith(begin)) {
                data = begin + data;
            }

            if (window.base64ImageCache[hash] === "getting")
                window.base64ImageCache[hash] = data;

            // console.log("%% YUP, APPEDNING IMAGE " + data.substr(0, 200))

            if (returnBase64) {
                return data;
            } else {

                setTimeout(function() {

                    let el = document.querySelectorAll('.image[data-rand="' + rand + '"]');
                    console.log("%% append possibel to: ", el)
    
                    if (el && el[0]) {
                        console.log("%% appending image to dom [0]", el)
                        let oldImg = el[0].querySelector("img");
                        if (oldImg && oldImg[0]) {
                            oldImg[0].parentNode.removeChild(oldImg[0]);
                        }
                        el[0].classList = "image";
                        // el[0].innerHTML = '';
                        var img = document.createElement("img");
                        img.setAttribute("src", data);
                        el[0].appendChild(img);

                        if (el[1]) {
                            console.log("%% appending image to dom [1]", el)
                            let oldImg = el[1].querySelector("img");
                            console.log("%%%%%%%%%%%%%%%%%%%%%%%%%% ODLIMG", oldImg)
                            if (oldImg && oldImg[0]) {
                                oldImg[0].parentNode.removeChild(oldImg[0]);
                            }
                            el[1].classList = "image";
                            // el[0].innerHTML = '';
                            var img = document.createElement("img");
                            img.setAttribute("src", data);
                            el[1].appendChild(img);
                        }
                    } else {
                        console.log("%% dom element not found to append image to " + rand)
                    }
    
                }.bind(this), 300)

                return "ok"

            }
        
        } else {
            
            console.log("%% COULDNT GET FILE IN ANY WAY")
            window.base64ImageCache[hash] = undefined

            // let el = document.querySelectorAll('.image[data-queue-id="' + queueId + '"]');

            // if (el && el[0]) {
            //     console.log("ERROR IMAGE LOAD: ", el);
            //     el[0].classList = "image error";
            // }

        }

    }

    static getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    static inDays(date) {
        const diffInMs = date - new Date();
        if (diffInMs < 0) {
            return "Due";
        } else {
            const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
            return "In " + Math.ceil(diffInDays) + "d"
        }
    }

    static timeSince(date, short) {

        var seconds = Math.floor((new Date() - date) / 1000);
      
        var interval = Math.floor(seconds / 31536000);

        if (seconds < 30) {
            return "just now"
        }
      
        if (interval > 1) {
          return interval + ((short) ? "y ago" : " years ago");
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
          return interval + ((short) ? "m ago" : " months ago");
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + ((short) ? "d ago" : " days ago");
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
          return interval + ((short) ? "h ago" : " hours ago");
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
          return interval + ((short) ? "m ago" : " minutes ago");
        } else {
            return (short) ? "1m ago" : "1 minute ago"
        }

      }


    static startAndEndOfMonth(date) {
        var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
        return [new Date(date.getFullYear(), date.getMonth(), 1), lastDayOfMonth];
    }

    static formatDate(dateObj, format) {

        var str = new Date();

        if (dateObj !== undefined) {
            str = new Date(dateObj);
        }

        // if ( (typeof dateObj === 'string') || (typeof dateObj === 'number') ) {
        //     str = new Date(dateObj * 1000);
        // }

        var dateStr = "";
        const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

        var months;
        var days;
        var years;
        var hours;
        var minutes;
        var seconds;
        var milliseconds;

        switch (format) {

            case "YYYYMM":
                years = str.getFullYear();
                dateStr = years + ((months < 10) ? ("0" + months) : months)
                break;

            case "MMM YYYY":
                months = (str.getMonth());
                years = str.getFullYear();
                dateStr = MONTHS_SHORT[months] + " " + years;
                break;

            case "DD":
                days = (str.getDate());
                if (days < 10) days = "0" + days;
                dateStr = days;
                break;

            case "DDd HHh":
                years = str.getFullYear();
                months = (str.getMonth()+1);
                days = (str.getDate());
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days;
                dateStr = months + "m " + days + "d " + hours + "h";
                break;

            case "DD/MM/YYYY HH:MM":
                years = str.getFullYear();
                months = (str.getMonth()+1);
                days = (str.getDate());
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days;
                dateStr = days + "/" + months + "/" + years + " " + hours + ":" + minutes;
                break;

            case "HH:MM":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                break;

            case "DD/MM/YYYY":
                months = (str.getMonth()+1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = days + "-" + months + "-" + years;
                break;

            case "YYYY-MM-DD":
                months = (str.getMonth()+1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = years + "-" + months + "-" + days;
                break;

            default:
                months = (str.getMonth()+1);
                if (months < 10) months = "0" + months
                years = str.getFullYear();
                if (years < 10) years = "0" + years
                days = (str.getDate());
                if (days < 10) days = "0" + days
                hours = str.getHours()
                if (hours < 10) hours = "0" + hours
                minutes = str.getMinutes()
                if (minutes < 10) minutes = "0" + minutes
                seconds = str.getSeconds()
                if (seconds < 10) seconds = "0" + seconds
                milliseconds = str.getMilliseconds();
                if (milliseconds < 10) milliseconds = "00" + milliseconds
                else if (milliseconds < 100) milliseconds = "0" + milliseconds
                dateStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes + ":" + seconds + "." + milliseconds
                break;

        }

        return dateStr;

    }

    static iOSFixDate(date) {

        if (typeof date === "string") {
            let year = date.substr(0, 4);
            let month = date.substr(5, 2);
            let day = date.substr(8, 2);
            let hour = date.substr(11, 2);
            let minute = date.substr(14, 2);
            let second = date.substr(17, 2);
            let ms = date.substr(20, 3)
            return new Date(year, month-1, day, hour, minute, second, ms)
        } else {
            return date;
        }

    }

    static shuffle(arr) {

        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }

        return arr;

    }

    // static concatWordParts(word, which) {
    //     let wordStr = which === "term" ? word.term : word.definition;
    //     if (which === "term" && word.termPrefix) wordStr = word.termPrefix + " " + word.term;
    //     if (which === "definition" && word.definitionPrefix) wordStr = word.definitionPrefix + " " + word.definition;
    //     return wordStr;
    // }

    static pause(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    static isValidURL(str) {
        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    static getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    static getGetOrdinal(n) {
        var s=["th","st","nd","rd"],
            v=n%100;
        return n+(s[(v-20)%10]||s[v]||s[0]);
     }

    static differenceBetweenDatesSeconds(date1, date2) {
        return Math.abs(date1 - date2) / 1000;
    }

    static toMMSS(date) {
        var seconds = Math.floor(date);
        var minutes = Math.floor(seconds / 60);
        seconds -= minutes*60;
    
        // if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds;
    }

    static prepareSetForExport(set) {
        for (let key in set) {
            let value = set[key];
            if (!value) {
                delete set[key];
            }
        }

        return set;
    }

    static prepareSettingForExport(setting) {
        if (setting.key === "flashcardStudyMode" || setting.key === "queueStudyMode" || setting.key === "syncMode" || setting.key === "licence") {
            return null;
        }
        return setting;
    }

    static getQueryParams(qs) {
        qs = qs.split('+').join(' ');
    
        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;
    
        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }
    
        return params;
    }

    static prepareCardFromDb(card) {
        if (!card.history) card.history = [];
        return card;
    }

    static prepareCardsForDb(words, CATEGORIES = window.INSTANCE_CONFIG.CATEGORIES, TAG_NAMES = window.INSTANCE_CONFIG.TAG_NAMES) {
        let tags = []
        words = words.map(item => {
            if (!item.ta) item.ta = [];
            item.tags = JSON.parse(JSON.stringify(item.ta));
            delete item.ta;

            item.term = item.te;
            delete item.te;
            item.definition = item.de;
            delete item.de;
            item.examples = JSON.parse(JSON.stringify(item.ex));
            delete item.ex;
            item.examples = item.examples && item.examples[0] ? item.examples.map(arr => { return { rel: arr[0], term: arr[1], definition: arr[2] } }) : []
            item.exampleCount = item.ec;
            delete item.ec

            for (let i = 0; i < item.tags.length; i++) {
                let tagId = item.tags[i];
                item[tagId] = "t"
                if (!tags.find(item => item.id === tagId)) {
                    tags.push({ id: tagId, name: TAG_NAMES[tagId] })
                }
            }
            item.strength = 0;
            // TO DO REMOVE
            // if (Math.random() < 0.05) {
            //     item.star = "t"
            // }
            // item.strength = Math.random() < 0.1 ? 5 : Math.random() < 0.2 ? 3 : Math.random() < 0.4 ? 1 : 0;
            // item.reviewAfter = Math.random() < 0.006 ? Utilities.randomIntFromInterval(1663338935, 1663863307) : 0;
            return item;
        });
        return {
            tags: tags,
            words: words
        }
    }

    static randomPreviewTerm() {
        const PREVIEW_SENTENCES = [
            "The phone is for you.",
            "El teléfono es para ti.",
            "Answer the phone.",
            "Coge el teléfono.",
            "I know the truth.",
            "Sé la verdad.",
            "I'm going to be the best.",
            "voy a ser el mejor",
            "It would be better for me to tell you the truth.",
            "Será mejor que te diga la verdad.",
            "He's the captain of a team.",
            "Él es el capitán de un equipo.",
            "We're all on the same team.",
            "Todos estamos en el mismo equipo.",
            "I want to be on the other team.",
            "Quiero estar en el otro equipo.",
            "I like being on the team.",
            "Me gusta estar en el equipo.",
            "He always has the first word.",
            "Él siempre tiene la primera palabra.",
            "You are the light of my eyes.",
            "Tú eres la luz de mis ojos.",
            "The light went out by itself.",
            "Se fue la luz.",
            "I had to tell the truth.",
            "Tuve que decir la verdad.",
            "My country is the greatest country in the world.",
            "Mi país es el país más grande en el mundo.",
            "I'm the middle child of three.",
            "Soy el segundo de tres hijos.",
            "The young lady wants this.",
            "La señorita quiere esto.",
            "The woman to whom you were talking is my older sister.",
            "La mujer con quien estabas hablando es mi hermana",
            "The children already went to school.",
            "Los niños ya fueron a la escuela.",
            "Only six people came to the party.",
            "Sólo había seis personas en la fiesta.",
            "No one is in the room.",
            "No hay nadie en la habitación."
        ]
        return PREVIEW_SENTENCES[Utilities.randomIntFromInterval(0, PREVIEW_SENTENCES.length - 1)]
    }

    static prepareCardForBackup(card) {
        if ( (card.history && card.history.length !== 0) || card.strength || card.star ) {
            let data = {
                id: card.id
            };
            if (card.history && card.history.length !== 0) data.history = card.history;
            if (card.lastReviewed) data.lastReviewed = card.lastReviewed;
            if (card.reviewAfter) data.reviewAfter = card.reviewAfter;
            if (card.reviewCount) data.reviewCount = card.reviewCount;
            if (card.revisionCycle) data.revisionCycle = card.revisionCycle;
            if (card.strength) data.strength = card.strength;
            if (card.star) data.star = card.star;
            return data;
        } else {
            return null;
        }
    }

    static randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    static getHeaderText(timestamp) {

        let now = new Date();
        let date = new Date(timestamp*1000);

        // console.log("generating header text " + date);

        if (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate()) {
            return "today"
        }

        let thisWeek = this.getMonday(now);
        thisWeek.setHours(0);
        thisWeek.setMinutes(0);
        thisWeek.setSeconds(1)

        if (date >= thisWeek) {
            return "thisWeek";
        }

        let thisMonth = new Date();
        thisMonth.setDate(1);
        thisMonth.setHours(0);
        thisMonth.setMinutes(0);
        thisMonth.setSeconds(1)

        if (date >= thisMonth) {
            return "thisMonth";
        }

        let lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        lastMonth.setHours(0);
        lastMonth.setMinutes(0);
        lastMonth.setSeconds(1)

        if (date >= thisMonth) {
            return "lastMonth";
        }

        return "Older";

    }

}

export default Utilities;
