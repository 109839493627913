import { IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SQLStorage from '../SQLStorage';
import '../styles/GoalProgress.scss';
import Utilities from '../Utilities';
// import PathImg from '../images/path.png'

class GoalProgress extends React.Component {

  constructor(props) {
      super(props);
      this.state = { }
  }

  async componentDidMount() {
    this.load();
  }
  
  async load() {
    let today = new Date();
    let week = Utilities.startAndEndOfWeek(today);
    let first = week[0];
    let last = week[1];
    let cards = await SQLStorage.getCardsBetweenReviewed(first.getTime()/1000, last.getTime()/1000);

    let i = 0;
    let labels = [window.ln.monA, window.ln.tueA, window.ln.wedA, window.ln.thuA, window.ln.friA, window.ln.satA, window.ln.sunA];
    let res = [];

    while (first < last) {
        let start = new Date(first);
        let end = new Date(first);
        let reviewedIds = [];
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        start = parseInt(start.getTime()/1000);
        end = parseInt(end.getTime()/1000);
        for (let i = 0; i < cards.length; i++) {
            const cardHistory = cards[i].history;
            for (let j = 0; j < cardHistory.length; j++) {
                const action = cardHistory[j].split("_")[0];
                const date = parseInt(cardHistory[j].split("_")[2]);
                if ( 
                    (action === "10" || action === "11" || action === "type" || action === "pronounce" || action === "select" || action.indexOf("term") !== -1 || action.indexOf("definition") !== -1) && 
                    reviewedIds.indexOf(cards[i].id === -1) &&
                    (date <= end && date >= start)
                ) {
                    reviewedIds.push(cards[i].id);
                }
            }
        }
        res.push({
            label: labels[i],
            count: reviewedIds.length,
            date: new Date(first),
            isToday: Utilities.formatDate(new Date(), "YYYY-MM-DD") === Utilities.formatDate(new Date(first), "YYYY-MM-DD")
        })
        i++
        first = Utilities.dateAdd(first, "day", 1);
    }

    console.log(res, "<<<<<");

    this.setState({ res : res })

  }

  render() {
      if (this.state.res) {
        let goalNumber = window.settings && window.settings.goalNumber ? parseInt(window.settings.goalNumber) : 0;
        let todayCount = this.state.res.find(item => { return Utilities.formatDate(new Date(), "YYYY-MM-DD") === Utilities.formatDate(new Date(item.date), "YYYY-MM-DD") }).count;
        return (
            <div 
                className="goal-progress"
                style={{
                   // backgroundImage: `url("${PathImg}")`
                }}
            >
                <h1 onClick={() => {
                    document.getElementById("goal-select").click();
                }}>{window.ln.reviewGoal}</h1>
                {/* <label><strong>{todayCount}</strong> of <strong>{goalNumber}</strong> completed today</label> */}
                <label 
                    onClick={() => {
                        document.getElementById("goal-select").click();
                    }}
                    style={{
                        zIndex: this.state.showGuide ? 100000 : undefined
                    }}
                >
                    {goalNumber} <button>{ goalNumber ? window.ln.edit : window.ln.setUp }</button> 
                </label>
                <div className="days">
                    { this.state.res.map(item => {
                        let donePercentage = goalNumber ? (item.count > goalNumber ? goalNumber : item.count) / goalNumber * 100 : 0;
                        return (
                            <div className="day">
                                <div className="label">{item.label}</div>
                                <div className="circle" data-today={item.isToday}>
                                    <CircularProgressbar
                                        value={Math.round(donePercentage)}
                                        text="" 
                                        styles={{
                                            path: {
                                                stroke: `rgba(255, 255, 255, 0.85)`,
                                            },
                                            trail: {
                                                stroke: 'rgba(255, 255, 255, 0.15)',
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }) }
                </div>
                <IonSelect
                    style={{
                        display: 'none'
                    }}
                    id="goal-select"
                    onIonChange={ async (i) => { 
                        Utilities.trackEvent("Daily Goal", "Daily Goal Update", i.detail.value);
                        setTimeout( async () => {
                            await SQLStorage.saveSetting("goalNumber", i.detail.value);
                            localStorage.setItem("preventAppOpenTrack", "true")
                            window.location.reload();
                        }, 1000)
                    }}
                    value={goalNumber}
                >
                    <IonSelectOption value={0}>{window.ln.off}</IonSelectOption>
                    { [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100].map((item, i) => {
                        return (<IonSelectOption key={item.hours} value={item}>{item} {window.ln.terms}</IonSelectOption>)
                    }) }
                </IonSelect>
            </div>
          );
      } else {
          return null;
      }
  }

};

export default GoalProgress;
