import { IonIcon, withIonLifeCycle } from '@ionic/react';
import React from 'react';
import '../styles/HelpOverlay.scss';

import { closeSharp } from 'ionicons/icons';
import ArrowBlueImg from '../images/blue-arrow.svg';
import ArrowWhiteImg from '../images/white-arrow.svg';

export class ArrowComponent extends React.Component {
    render() {
        return (
            <div 
                className="arrow-wrapper"
                style={this.props.style}
                data-direction={this.props.direction}
            >
                <img src={this.props.blueArrow ? ArrowBlueImg : ArrowWhiteImg} />
                <label
                    style={this.props.styleLabel || {}}
                >{this.props.text}</label>
            </div>
        )
    }
}

class HelpOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        document.body.classList.add("help-overlay-showing");
    }

    componentWillUnmount() {
        document.body.classList.remove("help-overlay-showing");
    }

    render() {

        // return null;

        return (
            <div 
                className="help-overlay"
                id="help-overlay"
                data-view={this.props.view}
                onClick={() => {
                    this.props.close();
                }}
            >
                <button>
                    <IonIcon icon={closeSharp} />
                </button>
            </div>
        );
    }

};


export default withIonLifeCycle(HelpOverlay);