import { FilesystemDirectory, FilesystemEncoding, Plugins } from '@capacitor/core';
import { IonAlert, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonLabel, IonLoading, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar, withIonLifeCycle } from '@ionic/react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React, { createRef } from 'react';
import SQLStorage from '../SQLStorage';
import Storage from '../Storage';
import '../styles/Flashcards.scss';
import '../styles/Studysets.scss';
import Utilities from '../Utilities';
import GoalProgress from './GoalProgress';
import HelpOverlay, { ArrowComponent } from './HelpOverlay';
import ReviewModal from './ReviewModal';
import FlashcardsImg from '../images/flashcards.png';
import NotificationsImg from '../images/notifications.png';
import QuizImg from '../images/quiz.png';
import { checkmarkCircle, checkmarkDoneCircle, closeCircle, cog, fileTrayFull, playCircle, star, stopwatch } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import { connect } from 'react-redux';
import Flashcard from './Flashcard';
import FlashcardEditModal from './FlashcardEditModal';

import FamilyRelationshipsImg from '../images/topics/family_relationships-min.jpg';
import PeopleImg from '../images/topics/people-min.jpg';
import BodyHealthImg from '../images/topics/body_health-min.jpg';
import SicknessInjuryImg from '../images/topics/sickness_injury-min.jpg';
import FoodImg from '../images/topics/food-min.jpg';
import ClothingImg from '../images/topics/clothing-min.jpg';
import HouseImg from '../images/topics/house-min.jpg';
import ShopsServicesImg from '../images/topics/shops_services-min.jpg';
import TransportTravelImg from '../images/topics/transport_travel-min.jpg';
import NatureWeatherImg from '../images/topics/nature_weather-min.jpg';
import ActivitiesMovementsImg from '../images/topics/activities_movements-min.jpg';
import LocationPlaceImg from '../images/topics/location_place-min.jpg';
import TimeFrequencyImg from '../images/topics/time_frequency-min.jpg';
import NumbersQuantityImg from '../images/topics/numbers_quantity-min.jpg';
import MeasuresImg from '../images/topics/measures-min.jpg';

import MaterialQualityImg from '../images/topics/material_quality-min.jpg';
import LifestyleFreetimeImg from '../images/topics/lifestyle_freetime-min.jpg';
import SportImg from '../images/topics/sport-min.jpg';
import CultureArtsImg from '../images/topics/culture_arts-min.jpg';
import MediaItImg from '../images/topics/media_it-min.jpg';
import EducationImg from '../images/topics/education-min.jpg';
import OccupationImg from '../images/topics/occupation-min.jpg';
import EconomtManufacturingImg from '../images/topics/economy_manufacturing-min.jpg';
import LawImg from '../images/topics/law-min.jpg';
import SocietyImg from '../images/topics/society-min.jpg';
import CooperationImg from '../images/topics/cooperation-min.jpg';
import CommuniationImg from '../images/topics/communication-min.jpg';
import NegotionImg from '../images/topics/negotions-min.jpg';
import LanguageImg from '../images/topics/language-min.jpg';
import EmotionsImg from '../images/topics/emotions-min.jpg';
import EnvironmentLivingImg from '../images/topics/environment_living-min.jpg';
import OwnershipImg from '../images/topics/ownership-min.jpg';
import BeingChangesImg from '../images/topics/being_changes-min.jpg';

import { ReactReader } from "react-reader"
import Axios from 'axios';

const { Filesystem } = Plugins;

const TOPIC_IMAGE_COVERS = {
    "family_relationships": FamilyRelationshipsImg,
    'people': PeopleImg,
    'body_health': BodyHealthImg,
    'sickness_injury': SicknessInjuryImg,
    'food': FoodImg,
    'clothing': ClothingImg,
    'house': HouseImg,
    'shops_services': ShopsServicesImg,
    'transport_travel': TransportTravelImg,
    'nature_weather': NatureWeatherImg,
    'activities_movements': ActivitiesMovementsImg,
    'location_place': LocationPlaceImg,
    'time_frequency': TimeFrequencyImg,
    'numbers_quantity': NumbersQuantityImg,
    'measures': MeasuresImg,
    'material_quality': MaterialQualityImg,
    'lifestyle_freetime': LifestyleFreetimeImg,
    'sport': SportImg,
    'culture_arts': CultureArtsImg,
    'media_it': MediaItImg,
    'education': EducationImg,
    'occupation': OccupationImg,
    'economy_manufacturing': EconomtManufacturingImg,
    'law': LawImg,
    'society': SocietyImg,
    'cooperation': CooperationImg,
    'communication': CommuniationImg,
    'negotions': NegotionImg,
    'emotions': EmotionsImg,
    'language': LanguageImg,
    'environment_living': EnvironmentLivingImg,
    'ownership': OwnershipImg,
    'being_changes': BeingChangesImg,
}

const { Device, LocalNotifications, Keyboard } = Plugins;

const ALL_TEST = [
    "gb_69649",
    "gb_69542",
    "gb_69615",
    "gb_23860",
    "gb_12057",
    "gb_44704",
    "gb_8747",
    "gb_27457",
    "gb_42197",
    "gb_6997",
    "gb_32465",
    "gb_63696",
    "gb_26645",
    "gb_5787",
    "gb_53897",
    "gb_41166",
    "gb_18652",
    "gb_68822",
    "gb_38732",
    "gb_7984",
    "gb_16196",
    "gb_41629",
    "gb_64592",
    "gb_37884",
    "gb_62370",
    "gb_11044",
    "gb_3614",
    "gb_56082",
    "gb_1439",
    "gb_25354",
    "gb_18527"
]

class Studysets extends React.Component {

    constructor(props) {
        super(props);
        this.pageRef = createRef();
        this.rendition = createRef();
        this.tocRef = createRef();
        this.state = {
            loading: true,
            keywords: "",
            openCardI: null,
            selectPracticeType: null,
            loaded: false,
            keyboardShowing: false,
            chunkSize: 0,
            showAllSearchResults: false,
            reviewModal: null,
            showGoalProgress: true,
            showGuide: !localStorage.getItem("help-overlay-hidden-studysets"),
            proPrompt: null,

            location: undefined,
            fontSize: 20,
            parsedContent: null,
            paginated: false
        }
    }

    handleFocus = () => {
        if (window.dropbox && (!localStorage.getItem("consolidateStorage"))) {
            window.dropbox.sync();
        }
    }

    async componentDidUpdate() {
        if (window.location.pathname.startsWith("/sets") && localStorage.getItem("justBackFromTagList")) {
            localStorage.removeItem("justBackFromTagList");
            this.load(true);
            return;
        }

        if (window.location.pathname.startsWith("/sets") && localStorage.getItem("justEndedPractice")) {
            if (this.blockedPracticeOpenAgain) {
                return;
            }
            
            this.blockedPracticeOpenAgain = true;
            setTimeout(() => {
                this.blockedPracticeOpenAgain = false
                Storage.remove("statsBeforePractice");
                localStorage.removeItem("justEndedPractice");
            }, 1000)

            let beforePractice = await Storage.getO("statsBeforePractice");
            let afterPractice = await Utilities.getReviewEndStats();
    
            if (beforePractice && afterPractice) {
                if (beforePractice.learnt === afterPractice.learnt && beforePractice.mastered === afterPractice.mastered && beforePractice.reviews === afterPractice.reviews && beforePractice.reviewedCardIdsToday === afterPractice.reviewedCardIdsToday ) { } else {
                    this.setState({
                        reviewModal: {
                            before: beforePractice,
                            after: afterPractice
                        }
                    })
                    this.load(true);
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.handleFocus);
    }

    componentDidMount() {
        // setTimeout(() => {
            this.setState({
                url: this.props.location.search.split("?url=").pop()
            })
        // }, 10000)
    }

    async load (reloadTags) {

        const bookFromLocal = null // await window.db.books.where("id").equals(id).toArray();
        // let content = bookFromLocal[0].content;
        // console.log(content);
        // content = content.split(/\*\*\* START OF THE PROJECT GUTENBERG EBOOK.*/gm)[1]
        // content = content.split(/\*\*\* END OF THE PROJECT GUTENBERG EBOOK.*/)[0]
        // content = content.replace(/(\r\n|\n|\r)/gm, " ");
        // console.log(content);

        if (bookFromLocal) {
            // this.setState({
            //     htmlContent: content
            // })
            // setTimeout(() => {
            //     document.getElementsByClassName("c-wrapper")[0].scrollTop = 625
            // }, 2000)
        } else {
            const book = await Axios.get("http://localhost:57713/.netlify/functions/getBook/19");
            console.log(book.data)
            if (book && book.data) {
                await window.db.books.put({
                    id: 0,
                    content: book.data
                })
                this.setState({
                    htmlContent: book.data.styles + " " + book.data.content.replace(/(\r\n|\n|\r)/gm, " ")
                }, () => {
                    console.log(this.state.htmlContent)
                })
            }
        }

        function getSelectionText() {
            var text = "";
            var activeEl = document.activeElement;
            var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
            if (
              (activeElTagName == "textarea") || (activeElTagName == "input" &&
              /^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
              (typeof activeEl.selectionStart == "number")
            ) {
                text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
            } else if (window.getSelection) {
                text = window.getSelection().toString();
            }
            return text;
        }
        
        document.onmouseup = document.onkeyup = document.onselectionchange = function() {
            // console.log("SELECTED: " + getSelectionText());
            if (window.getSelection().anchorNode) {
                const allNodes = [...document.querySelectorAll(".book-content>*")];
                const selectedNode = window.getSelection().anchorNode.parentElement;
                let selectedNodeNthChild = null;
                for (let i = 0; i < allNodes.length; i++) {
                    const node = allNodes[i];
                    if (node.isEqualNode(selectedNode)) {
                        selectedNodeNthChild = i;
                        break
                    }
                }
                
                console.log(selectedNodeNthChild, "<<<<>>>>>>.",  window.getSelection())
    
                this.setState({
                    selectedText: getSelectionText(),
                    selectedTextAnchor: getSelection().anchorNode
                })
            } else {
                this.setState({
                    selectedText: null
                })
            }
           
        }.bind(this);

        return

        this.setState({
            showGoalProgress: false
        }, () => {
            this.setState({
                showGoalProgress: true
            })
        })

        this.setState({
            loaded: true
        })

        const lastPractice = await Storage.getO("lastPractice");
        if (lastPractice) {
            console.log("LAST PRACTICE: ", lastPractice)
            if (lastPractice.type === "quiz") {
                this.props.history.push("/practice");
            } else if (lastPractice.type === "notifications") {
                this.props.history.push("/queue");
            } else if (lastPractice.type === "flashcards") {
                this.props.history.push("/flashcards");
            }
        }
    }

    async search() {
    
        if (this.state.keywords) {
            Utilities.trackEvent("Flashcards", "Flashcards Global Search", this.state.keywords.trim().toLowerCase());
            let matches = await SQLStorage.searchCards(this.state.keywords.trim().toLowerCase());
            this.setState({ matches: matches.length === 0 ? null : matches})
        } else {
            this.setState({ matches: null })
        }

    }

    async setPracticeType(type, type2) {

        await Utilities.saveCurrentStats();

        await Storage.setO("lastPractice", {
            type: type,
            content: type2,
            filters: [],
            total: this.state.selectedPracticeTypeNumber,
            chunk: this.state.chunkSize,
            chunkI: 0
        })

        if (type === "quiz") {
            this.props.history.push("/practice");
        } else if (type === "notifications") {
            let pending = await LocalNotifications.getPending();
            if (pending.notifications.length !== 0) {
                await LocalNotifications.cancel(pending);
            }
            await Storage.remove("notifications");
            this.props.history.push("/queue");
        } else if (type === "flashcards") {
            this.props.history.push("/flashcards");
        }

        this.setState({ selectPracticeType: null })

    }

    ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
      }
      
    str2ab(str) {
        var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
        var bufView = new Uint16Array(buf);
        for (var i=0, strLen=str.length; i<strLen; i++) {
          bufView[i] = str.charCodeAt(i);
        }
        return buf;
      }


    // async componentDidMount() {
        // const book = await Axios.get("https://effulgent-otter-b1e5c0.netlify.app/pg2641-images-3.epub",  { responseType: 'arraybuffer' })

        // if (!book || !book.data) {
        //     return null;
        // }

        // var
        //     binaryString = '',
        //     bytes = new Uint8Array(book.data),
        //     length = bytes.length;
        // for (var i = 0; i < length; i++) {
        //     binaryString += String.fromCharCode(bytes[i]);
        // }

        // console.log(binaryString, book.data)

        // const res = await Filesystem.writeFile({ path: 'book.epub', data: binaryString, directory: FilesystemDirectory.Data, encoding: FilesystemEncoding.UTF8 }).catch(e => {
        //     alert(e);
        // })

        // console.log(res);
        
    
        // let data = await Filesystem.readFile({ path: 'book.epub', directory: FilesystemDirectory.Data, encoding: FilesystemEncoding.UTF8 }).catch(e => {
        //     alert(e);
        // })

        // console.log(data)

        // this.setState({
        //     url: Uint8Array.from(data.data, x => x.charCodeAt(0))
        // })


    // }

    render() {

        if (this.state.parsedContent !== null) {
            return (
                <div id="parsed-content">
                    {JSON.stringify(this.state.parsedContent)}
                </div>
            )
        }

        console.log(this.state.selectedTexts)
        let htmlRendered = this.state.htmlContent;

        if (htmlRendered) {
            htmlRendered = htmlRendered.replace(/<i>/gi, "").replace(/<\/i>/gi, "")
            // htmlRendered = htmlRendered.replace(/<\/?a[^>]*>/g,"");
            if (this.state.selectedTexts) {
                for (let i = 0; i < this.state.selectedTexts.length; i++) {
                    const text = this.state.selectedTexts[i];
                    // console.log(Utilities.escapeRegExp(text))
                    // console.log(text + " : " + htmlRendered.indexOf(text), htmlRendered)
                    htmlRendered = htmlRendered.replace(new RegExp(Utilities.escapeRegExp(text), "gm"), '<span class="highlight">' + text + "</span>")
                }
            }
        }

        if (this.state.htmlContent) {
            return (
                <div onScroll={(e) => {
                    console.log(document.getElementsByClassName("c-wrapper")[0].scrollTop)

                    let el, top, min = Number.MAX_VALUE, els = document.querySelectorAll('.book-content>*');
                    for (let i=0; i<els.length; i++) {
                        top = Math.abs(els[i].getBoundingClientRect().top);
                        if (top < min) {
                            min = top;
                            el = els[i];
                        }
                    }
                    console.log(el)
                }} className='c-wrapper' style={{ overflow: 'scroll', height: '100%' }}>
                    <div className='options' style={{ position: 'absolute', left: 20, top: 20 }}>
                        { this.state.selectedText &&
                            <button onClick={() => {
                                // console.log(this.state.selectedText)
                                let selectedTexts = this.state.selectedTexts || [];
                                if (selectedTexts.indexOf(this.state.selectedText) === -1) {
                                    selectedTexts.push(this.state.selectedText);
                                }
                                this.setState({
                                    selectedTexts: selectedTexts
                                })
                                // getSelection().removeAllRanges();
                            }}>Highlight</button>
                        }
                        <button onClick={() => {
                            this.setState({
                                fontSize: this.state.fontSize - 4
                            })
                        }}>- Font</button>
                        <button onClick={() => {
                            this.setState({
                                fontSize: this.state.fontSize + 4
                            })
                        }}>+ Font</button>
                    </div>
                    <div className='book-content' style={{ fontSize: this.state.fontSize + 'px' }} dangerouslySetInnerHTML={{ __html: htmlRendered }}>

                    </div>
                </div>
            )
        }

        if (this.state.url)
        return (
            <React.Fragment>
            <ReactReader
                location={this.state.location}
                locationChanged={ async (l, a) => {
                    console.log("Loc change", l)
                    this.setState({
                        location: l
                    })

                    if (this.rendition.current) {
                        const startRange = this.rendition.current.getRange(l)
                        const sectionText = startRange.startContainer.nodeValue;
                        const visibleText = sectionText.substr(startRange.startOffset);
                        console.log(startRange, visibleText)
                    }

                    // console.log(selection.startContainer.textContent, selection.startOffset, selection.endOffset, selection.startContainer.textContent.substr(selection.startOffset, selection.endOffset - selection.startOffset))

                    const spine = await this.rendition.current.book.loaded.spine
                    console.log(spine, "<<<");

                    const res = await new Promise( async (resolve) => {
                        let results = [];
                        // for (let j = 0; j < spine.items.length; j++) {
                        //     const item = spine.items[j];
                        //     console.log(item)
                        //     // const contents = await item.load(this.rendition.current.book.load.bind(this.rendition.current.book));
                        //     // results.push(contents) //.innerText.split("."))
                        //     // if (results.length === spine.length) {
                        //     //     resolve(results)
                        //     // }
                        // }
                        // let order = [];
                        // let orderObj = {};
                        spine.each(item => {
                            console.log("@@ Load")
                            item.load(this.rendition.current.book.load.bind(this.rendition.current.book)).then((contents) => {
                                console.log("@@ Got: ");
                                results.push(contents) //.innerText.split("."))
                                if (results.length === spine.length) {
                                    resolve(results)
                                }
                            });
                        })
                    })

                    if (this.tocRef.current) {
                        // console.log(this.tocRef.current);
                        // return;

                        const tocFormatted = this.tocRef.current.map(item => {
                            return {
                                id: item.id.split("#")[1],
                                label: item.label,
                            }
                        });

                        let tocFormattedRedundant = JSON.parse(JSON.stringify(tocFormatted));

                        console.log(tocFormatted)
                        // return;
    
                        let chapters = []
                        let currentChapter = [];
                        let currentChapterName = "";
                        
                        res.map(item => {
                            const elements = item.querySelectorAll("h1,h2,h3,h4,h5,h6,p"); // ,img");
                            for (let i = 0; i < elements.length; i++) {
                                const element = elements[i];
                                const chapterSeperator = tocFormattedRedundant.find(tocI => tocI.id === element.id || tocI.id === element.parentElement.id);
                                if (chapterSeperator) {
                                    if (currentChapterName !== "") {
                                        chapters.push({
                                            name: currentChapterName,
                                            content: JSON.parse(JSON.stringify(currentChapter))
                                        })
                                        currentChapter = [];
                                    }
                                    currentChapterName = tocFormattedRedundant.find(tocI => tocI.id === element.id || tocI.id === element.parentElement.id).label;
                                    console.log("Found: " + currentChapterName);
                                }
                                tocFormattedRedundant = tocFormattedRedundant.filter(tocI => tocI.id !== element.id && tocI.id !== element.parentElement.id)
                                currentChapter.push([
                                    element.tagName,
                                    element.tagName === 'img' ? element.src.split("/").pop() : element.innerText
                                ])
                            }
                        })

                        if (currentChapter.length !== 0) {
                            chapters.push({
                                name: currentChapterName,
                                content: JSON.parse(JSON.stringify(currentChapter))
                            })
                        }

                        let txt = "";
                        let txtChapterLabels = [];

                        const mediaBlobUrls = this.rendition.current.book.resources.replacementUrls
                        const mediaUrls =  this.rendition.current.book.resources.urls

                        chapters.map(c => {
                            txt += "Chapter name: " + c.name + "\n\n"
                            txtChapterLabels.push(c.name);

                            c.content.map(p => {
                                txt += p[1] + "\n"
                            })
                            
                            txt += "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"
                        })

                        for (let i = 0; i < chapters.length; i++) {
                            const c = chapters[i];
                            for (let j = 0; j < c.content.length; j++) {
                                const p = c.content[j];

                                if (p[0] === "img") {
                                    let resized = null;
                                    if (mediaUrls.indexOf(p[1]) !== -1) {
                                        resized = await Utilities.resizeImage(mediaBlobUrls[mediaUrls.indexOf(p[1])], 600);
                                    }
                                    if (!resized) {
                                        c.content.splice(j, 1);
                                        j--;
                                    } else {
                                        p[1] = resized
                                    }
                                }
                            }
                        }

                        // console.clear()

                        // console.log(chapters.length, tocFormatted.length)
                        // console.log(txtChapterLabels);
                        // console.log(txt);
                        // console.log(chapters)

                        if (this.state.paginated) {
                            this.setState({
                                parsedContent: {
                                    chapters: chapters,
                                    error: chapters.length !== tocFormatted.length ? "Chapter & Toc mismatch" : null
                                }
                            })
                        } else {
                            this.setState({
                                paginated: true
                            }, () => {
                                document.querySelectorAll("button")[2].click();
                            })
                        }
                    }


                    if (this.rendition.current && this.tocRef.current) {
                        const { displayed, href } = this.rendition.current.location.start
                        const chapter = this.tocRef.current.find((item) => item.href === href)
                        this.setState({
                            page: `Page ${displayed.page} of ${displayed.total} in chapter ${chapter ? chapter.label : 'n/a'}`
                        })
                      }
                }}
                getRendition={(rendition) => {
                    this.rendition.current = rendition
                }}
                tocChanged={(toc) => {
                    console.log("toc Change", toc)
                    this.tocRef.current = toc;
                }}
                handleTextSelected={(cfiRange, contents) => {
                    console.log(cfiRange, this.rendition)
                    const selection = this.rendition.current.getRange(cfiRange);
                    console.log(selection.startContainer.textContent, selection.startOffset, selection.endOffset, selection.startContainer.textContent.substr(selection.startOffset, selection.endOffset - selection.startOffset))
                }}
                epubOptions={{
                    allowScriptedContent: true,

                }}
                url={this.state.url.endsWith('.epub') ? this.state.url : (this.state.url + "?v=1.epub")}
            />
            <div style={{ position: 'absolute', bottom: '1rem', right: '1rem', left: '1rem', textAlign: 'center', zIndex: 1}}>
                PAGE: {this.state.page}
            </div>
            </React.Fragment>
        )

        if (!this.state.loaded)
            return null;

        const dueCards = this.state.tags ? this.state.tags.due.total : 0;
        const studyingCards = this.state.tags ? this.state.tags.studying.total : 0;
        const learntCards = this.state.tags ? this.state.tags.learnt.total : 0;
        const masteredCards = this.state.tags ? this.state.tags.mastered.total : 0;
        const newCards = this.state.tags ? this.state.tags.new.total : 0;

        return (
            <IonPage data-page="studysets" ref={this.pageRef}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => { this.props.history.push("/settings"); }}>
                                <IonIcon icon={cog} />
                            </IonButton>
                        </IonButtons>
                        <IonTitle>{window.INSTANCE_CONFIG.APP_NAME}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent data-onboarding={(this.state.tags) ? "false" : "true"}>
                    { (this.state.tags) &&
                        <div className="search-options">
                            <div>
                                <IonSearchbar
                                    debounce={700}
                                    id="search-bar"
                                    showCancelButton="focus"
                                    value={this.state.keywords}
                                    onIonChange={(e) => {
                                        console.log("@@ ionIonChange()")
                                        this.search();
                                    }}
                                    onKeyUp={(e) => {
                                        console.log("@@ onKeyUp()")
                                        this.setState({
                                            keywords: e.target.value.toLowerCase()
                                        })
                                    }}
                                    placeholder={window.ln.searchInAll}
                                    onIonClear={() => {
                                        this.setState({
                                            keywords: ""
                                        }, function() {
                                            this.search()
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                    { (!this.state.keywords && this.state.tags && this.state.showGoalProgress) &&
                        <div className='goal-progress-wrapper'>
                            <GoalProgress
                                dueCards={dueCards}
                                masteredCards={masteredCards}
                                studyingCards={studyingCards}
                                learntCards={learntCards}
                                newCards={newCards}
                                {...this.props}
                            />
                        </div>
                    }
                    { (this.state.keywords) &&
                        <div 
                            className="search-wrapper"
                            style={{
                                height: 'unset'
                            }}
                        >
                            { ( (this.state.keywords) && (!this.state.matches) ) &&
                                <div className="not-enough-keywords">
                                    <h2>{window.ln.noMatch}</h2>
                                </div>
                            }
                            <div className="cards">
                                { (this.state.matches) && this.state.matches.map((item, i) => {
                                    if ( (!item.term) && (!item.definition) ) return null;
                                    if (!this.state.showAllSearchResults && i > 2) return null;
                                    return (
                                        <React.Fragment>
                                            <Flashcard
                                                onClick={ async () => { 
                                                    if (this.state.keyboardShowing) {
                                                        let el = document.querySelector(".search-options");
                                                        console.log(el);
                                                        if (el) {
                                                            el.click();
                                                            await new Promise((resolve) => { setTimeout(() => { resolve() }, 600) });
                                                        }
                                                    }
                                                    this.setState({ openCardI: i }) 
                                                }}
                                                tags={this.state.tags}
                                                flashcard={item} 
                                                i={i} 
                                                setName={item.setName}
                                                matchKeyword={this.state.keywords}
                                                definition={item.setDefinition} 
                                                term={item.setTerm} 
                                                definitionPronouncation={(window.availableLanguages.indexOf(item.setDefinition) > -1)} 
                                                termPronouncation={(window.availableLanguages.indexOf(item.setTerm) > -1)} 
                                            />
                                            { (i === 2 && !this.state.showAllSearchResults) &&
                                                <button 
                                                    className='main' 
                                                    onClick={() => {
                                                        this.setState({
                                                            showAllSearchResults: true                                                   
                                                        })
                                                    }}
                                                    style={{
                                                        marginTop: 15
                                                    }}
                                                >{window.ln.showAll}</button>
                                            }
                                        </React.Fragment>
                                    )
                                }) }
                            </div>
                        </div>
                    }
                    { (!this.state.tags) &&
                        <IonLoading
                            isOpen={!this.state.tags}
                            message={window.ln.loading}
                            duration={200000}
                        />
                    }
                    { (this.state.tags && !this.state.keywords) &&
                        <div 
                            className="sets"
                            style={ (this.state.keywords) ? {
                                height: 'unset',
                                minHeight: 'unset'
                            } : { } }
                        >
                            { window.INSTANCE_CONFIG.CATEGORIES && window.INSTANCE_CONFIG.CATEGORIES.map((category) => {
                                return (
                                    <div className='category'>
                                        <h2>{category.label}</h2>
                                        <div className='tags'>
                                            { category.tags.map((tagId, tagI) => {
                                                if (["rating", "conditions", "understanding", "needs_possibilities", "ambitions", "behavior"].indexOf(tagId) !== -1)
                                                    return null;
                                                const tag = this.state.tags[tagId];
                                                if (tag) {
                                                    return (
                                                        <div
                                                            className='tag-item'
                                                            onClick={() => {
                                                                if ( (category.id === "tops" || category.id === "topics") && (tagI > 2) && !window.activated ) {
                                                                    this.setState({ proPrompt: true })
                                                                    return null;
                                                                }

                                                                if (this.state.showGuide) {
                                                                    localStorage.setItem("help-overlay-hidden-studysets", "true")
                                                                    this.setState({
                                                                        showGuide: false
                                                                    })
                                                                }

                                                                this.setState({
                                                                    selectPracticeType: tagId,
                                                                    selectedPracticeTypeNumber: tag.total,
                                                                    chunkSize: 10
                                                                }, () => {
                                                                    Utilities.onDrawerShow();
                                                                })
                                                            }}
                                                            style={{
                                                                zIndex: (this.state.showGuide && (tagId === "new" || tagId === "due" || tagId == "mastered")) ? 100000 : undefined,
                                                                overflow: ["due", "new", "studying", "learnt", "mastered", "star"].indexOf(tagId) === -1 ? 'hidden' : undefined
                                                            }}
                                                        >
                                                            { ((tagId === "new" || tagId === "due" || tagId === "mastered") && this.state.showGuide) &&
                                                                <ArrowComponent 
                                                                    direction={tagId === "due" ? "right-bottom" : tagId === "new" ? (window.innerWidth < 724 ? "right-top" : "right-bottom") : "right-bottom" }
                                                                    text={tagId === "due" ? window.ln.arrowDue : tagId === "new" ? window.ln.arrowNew : window.ln.arrowMastered} 
                                                                    styleLabel={{ width: tagId === "mastered" ? 240 : 200 }} 
                                                                    style={tagId === "due" ? { top: -54, right: 34 } : tagId === "new" ? (window.innerWidth < 724 ? { bottom: -52, right: 34 } : { right: 34, bottom: 70 } ) : ( window.innerWidth < 724 ? { top: -84, right: 34 } : { top: -64, right: 34 } ) }
                                                                />
                                                            }
                                                            { (["due", "new", "studying", "learnt", "mastered", "star"].indexOf(tagId) === -1) ?
                                                                <div className='ti-background'>
                                                                    { (TOPIC_IMAGE_COVERS[tagId]) &&
                                                                        <div className='image-background' style={{ backgroundImage: 'url("' + TOPIC_IMAGE_COVERS[tagId] + '")' }}></div>
                                                                    }
                                                                    <div style={{ opacity: TOPIC_IMAGE_COVERS[tagId] ? 0.35 : 1, background: "linear-gradient(to right, " + window.tagGradients.blue[0] + ", " + window.tagGradients.blue[1] + ")", left: 0, width: tag.percentages.new + "%" }}></div>
                                                                    <div style={{ opacity: TOPIC_IMAGE_COVERS[tagId] ? 0.35 : 1, background: "linear-gradient(to right, " + window.tagGradients.orange[0] + ", " + window.tagGradients.orange[1] + ")", left: tag.percentages.new + "%", width: tag.percentages.new + tag.percentages.studying + "%" }}></div>
                                                                    <div style={{ opacity: TOPIC_IMAGE_COVERS[tagId] ? 0.35 : 1, background: "linear-gradient(to right, " + window.tagGradients.green[0] + ", " + window.tagGradients.green[1] + ")", left: tag.percentages.new + tag.percentages.studying + "%", width: tag.percentages.new + tag.percentages.studying + tag.percentages.learnt + "%" }}></div>
                                                                    <div style={{ opacity: TOPIC_IMAGE_COVERS[tagId] ? 0.35 : 1, background: "linear-gradient(to right, " + window.tagGradients.purple[0] + ", " + window.tagGradients.purple[1] + ")", left: tag.percentages.new + tag.percentages.studying + tag.percentages.learnt + "%", width: tag.percentages.new + tag.percentages.studying + tag.percentages.learnt + tag.percentages.mastered + "%" }}></div>
                                                                </div>
                                                                :
                                                                <div className='ti-background' style={{ background: 
                                                                    tagId === "due" ? ("linear-gradient(to right, " + window.tagGradients.pink[0] + ", " + window.tagGradients.pink[1] + ")") : 
                                                                    tagId === "new" ? ("linear-gradient(to right, " + window.tagGradients.blue[0] + ", " + window.tagGradients.blue[1] + ")") : 
                                                                    tagId === "studying" ? ("linear-gradient(to right, " + window.tagGradients.orange[0] + ", " + window.tagGradients.orange[1] + ")") : 
                                                                    tagId === "learnt" ? ("linear-gradient(to right, " + window.tagGradients.green[0] + ", " + window.tagGradients.green[1] + ")") : 
                                                                    tagId === "mastered" ? ("linear-gradient(to right, " + window.tagGradients.purple[0] + ", " + window.tagGradients.purple[1] + ")") : 
                                                                    tagId === "star" ? ("linear-gradient(to right, " + window.tagGradients.yellow[0] + ", " + window.tagGradients.yellow[1] + ")") :
                                                                    "#333"
                                                                }} ></div>
                                                            }
                                                            <div className='details'>
                                                                <span>
                                                                    { (["due", "new", "studying", "learnt", "mastered", "star"].indexOf(tagId) !== -1) &&
                                                                        <IonIcon icon={
                                                                            tagId === "due" ? stopwatch :
                                                                            tagId === "new" ? fileTrayFull :
                                                                            tagId === "studying" ? playCircle :
                                                                            tagId === "learnt" ? checkmarkCircle :
                                                                            tagId === "mastered" ? checkmarkDoneCircle :
                                                                            tagId === "star" ? star :
                                                                            closeCircle 
                                                                        } />
                                                                    }
                                                                    {tag.label || window.ln[tagId]}
                                                                </span>
                                                                <span>{tag.total}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                )
                            }) }
                        </div>
                    }
                    { (this.state.keywords) &&
                        <IonFab vertical="bottom" horizontal="start" slot="fixed" style={{ width: "calc(100% - 20px)", display: "flex", justifyContent: "center", marginBottom: 10 }}>
                            <IonFabButton style={{ width: 184, '--border-radius': '56px', '--background': '#fb5b5a' }} onClick={() => {
                                this.setState({
                                    keywords: ""
                                }, function() {
                                    this.search()
                                })
                            }}>
                                <IonIcon icon={closeCircle} />
                                <IonLabel style={{ marginLeft: 8, fontWeight: 600, position: "relative", top: -1 }}>{window.ln.clearFilters}</IonLabel>
                            </IonFabButton>
                        </IonFab>   
                    }
                    { (this.state.showGuide) &&
                        <HelpOverlay 
                            view="goal-progress-modal"
                            close={() => {
                                localStorage.setItem("help-overlay-hidden-studysets", "true")
                                this.setState({
                                    showGuide: false
                                })
                            }}
                        />    
                    }
                    { (this.state.openCardI !== null) &&
                        <FlashcardEditModal
                            open={this.state.matches[this.state.openCardI].id}
                            flashcard={this.state.matches[this.state.openCardI]}
                            onClose={ async (changedCard, autoAddNew, addedNewImage) => { this.setState({ openCardI: null, openCardNew: false }) }}
                        />
                    }
                </IonContent>
                <SwipeableDrawer
                    anchor="bottom"
                    open={this.state.selectPracticeType !== null}
                    key="flashcard-editor"
                    transitionDuration={0}
                    className="type-selector"
                    onOpen={ () => {
                        Utilities.onDrawerShow();
                    } }
                    onClose={ () => { 
                        Utilities.onDrawerHide();
                        this.setState({ selectPracticeType: null }) 
                    } }
                >
                    <div data-keyboard-showing={this.state.keyboardShowing} ref={this.modalRef} className="swipeable-drawer-body" className="type-selector-modal" style={{ 
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        padding: 15,
                        background: "white",
                        borderRadius: '24px 24px 0 0'
                    }} >
                        <div className="drag-indicator"></div>
                        <h2 style={{ textAlign: "center", marginBottom: 12, fontSize: '1em', fontWeight: 700 }}>
                            {window.ln.selectPracticeType + ' '}
                            { window.ln.forXYCards.replace("__", this.state.selectedPracticeTypeNumber). replace("___", window.INSTANCE_CONFIG.TAG_NAMES[this.state.selectPracticeType] || this.state.selectPracticeType) }
                            {', '}
                            { (this.state.selectPracticeType === "new") ?
                                window.ln.sortHintNew :
                                (this.state.selectPracticeType === "due") ?
                                    window.ln.sortHintDue :
                                    window.ln.sortHintOther
                            }
                        </h2>
                        <div className="drag-close-indicator" onClick={() => {
                            Utilities.onDrawerHide();
                            this.setState({ selectPracticeType: null }) 
                        }}>
                            <IonIcon icon={closeCircle} />
                        </div>
                        { (this.state.selectedPracticeTypeNumber > 10) &&
                            <div className='chunks'>
                                <h3>{window.ln.cardsPerRound}</h3>
                                <IonSegment value={this.state.chunkSize.toString()} onIonChange={e => {
                                    this.setState({
                                        chunkSize: parseInt(e.detail.value),
                                    })
                                }}>
                                    <IonSegmentButton value="10">
                                        <IonLabel>10</IonLabel>
                                    </IonSegmentButton>
                                    { (this.state.selectedPracticeTypeNumber >= 20) &&
                                        <IonSegmentButton value="20">
                                            <IonLabel>20</IonLabel>
                                        </IonSegmentButton>
                                    }
                                    { (this.state.selectedPracticeTypeNumber >= 30) &&
                                        <IonSegmentButton value="30">
                                            <IonLabel>30</IonLabel>
                                        </IonSegmentButton>
                                    }
                                    { (this.state.selectedPracticeTypeNumber >= 40) &&
                                        <IonSegmentButton value="40">
                                            <IonLabel>40</IonLabel>
                                        </IonSegmentButton>
                                    }
                                    { (this.state.selectedPracticeTypeNumber >= 50) &&
                                        <IonSegmentButton value="50">
                                            <IonLabel>50</IonLabel>
                                        </IonSegmentButton>
                                    }
                                </IonSegment>
                            </div>
                        }
                        <div className="drag-close-indicator" onClick={() => {
                            Utilities.onDrawerHide();
                            this.setState({  selectPracticeType: null }) 
                        }}>
                            <IonIcon icon={closeCircle} />
                        </div>
                        {/* <IonIcon onClick={() => { this.setState({  selectPracticeType: null }) }} icon={closeCircle} style={{ position: "absolute", top: 0, right: 0, padding: "9px 12px", fontSize: 24 }} /> */}
                        <div className="activities-wrapper select-type">
                            <div>
                                <div data-disabled={dueCards === 0 && this.state.selectPracticeType === "due" || newCards === 0 && this.state.selectPracticeType === "new"} style={{ backgroundColor: "#1D6AE5" }} data-activity="cards" onClick={() => { 
                                    if (dueCards === 0 && this.state.selectPracticeType === "due" || newCards === 0 && this.state.selectPracticeType === "new") { } else {
                                        this.setPracticeType("flashcards", this.state.selectPracticeType) 
                                    }  
                                }}>
                                    <img src={FlashcardsImg} />
                                    <div>
                                        <p>
                                            <strong>{window.ln.flashcards}</strong>
                                            {window.ln.flashcardsWhen}
                                        </p>
                                    </div>
                                </div>
                                <div data-disabled={dueCards === 0 && this.state.selectPracticeType === "due" || newCards === 0 && this.state.selectPracticeType === "new"} style={{ backgroundColor: "#7536EF" }} data-activity="queue" onClick={() => { 
                                    if (!window.activated && (!Utilities.withinFreeTrial("notifications"))) {
                                        window.showSubscriptionModal("notifications");
                                        this.setState({ setPracticeType: false })
                                        return;
                                    }
                                    localStorage.setItem("tried-notifications", "true");

                                    if (dueCards === 0 && this.state.selectPracticeType === "due" || newCards === 0 && this.state.selectPracticeType === "new") { } else {
                                        this.setPracticeType("notifications", this.state.selectPracticeType) 
                                    }
                                }}>
                                    <img src={NotificationsImg} />
                                    <div>
                                        <p>
                                            <strong>{window.ln.notifications}</strong>
                                            {window.ln.notificationsWhen}
                                        </p>
                                    </div>
                                </div>
                                <div data-disabled={dueCards === 0 && this.state.selectPracticeType === "due" || newCards === 0 && this.state.selectPracticeType === "new"} style={{ backgroundColor: "#2dce91" }} data-activity="practice" onClick={() => { 
                                    if (!window.activated && (!Utilities.withinFreeTrial("quiz"))) {
                                        window.showSubscriptionModal("practice");
                                        this.setState({ setPracticeType: false })
                                        return;
                                    }
                                    localStorage.setItem("tried-practice", "true");

                                    if (dueCards === 0 && this.state.selectPracticeType === "due" || newCards === 0 && this.state.selectPracticeType === "new") { } else {
                                        this.setPracticeType("quiz", this.state.selectPracticeType) 
                                    }   
                                }}>
                                    <img src={QuizImg} />
                                    <div>
                                        <p>
                                            <strong>{window.ln.quiz}</strong>
                                            {window.ln.quizWhen}
                                        </p>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: "#444" }} data-activity="list" onClick={() => { 
                                    this.setState({ selectPracticeType: null })
                                    this.props.history.push("/tag/" + this.state.selectPracticeType);
                                }}>
                                    <div>
                                        <p style={{ textAlign: "center" }}>{window.ln.showList}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* { ((dueCards === 0 && this.state.selectPracticeType === "due") || (newCards === 0 && this.state.selectPracticeType === "new")) &&
                            <p>{ (dueCards === 0 && this.state.selectPracticeType === "due") ? window.ln.noDueCards : window.ln.noNewCards } </p>
                        } */}
                    </div>
                </SwipeableDrawer>
                <ReviewModal
                    {...this.props}
                    data={this.state.reviewModal}
                    onClose={() => { this.setState({ reviewModal: null })  }}
                />
                <IonAlert
                    isOpen={this.state.proPrompt}
                    onDidDismiss={() => { this.setState({ proPrompt: null }) }}
                    header={window.ln.proTopics}
                    buttons={[
                        {
                            text: window.ln.discoverPro,
                            handler: () => { window.showSubscriptionModal("topics") }
                        },
                        {
                            text: window.ln.close,
                            role: 'cancel',
                        }
                    ]}
                />
            </IonPage>
        );
    }

};


export default withIonLifeCycle(Studysets);
