import Storage from './Storage';
import Utilities from './Utilities';
import SQLStorageImported from './SQLStorage';
import { create, language } from 'ionicons/icons';

class SQLStorage {

    static async saveCard(card) {
        await window.db.cards.put(card);
    }

    static async addToLibrary(libraryItem) {
        await window.db.libraryItemContent.put({
            id: libraryItem.id,
            chapters: libraryItem.chapters
        });

        const lastChapter = libraryItem.chapters[libraryItem.chapters.length-1];
        const lastSentence = lastChapter.content[lastChapter.content.length - 1];

        delete libraryItem.chapters;
        delete libraryItem.caption;
        await window.db.libraryItem.put({
            ...libraryItem,
            sentenceCount: lastSentence[3]
        });
        await window.db.libraryItemProgress.put({
            chapter: 0, // Chapter absolute
            sentence: 0, // Sentence Relative to chapter

            paragraphAbs: 0,
            sentenceAbs: 0,
            id: libraryItem.id
        });
    }

    static async saveProgress(progress) {
        await window.db.libraryItemProgress.put(progress);
    }
    
    static async getBookProgressById(id) {
        let books = await window.db.libraryItemProgress.where("id").equals(id).toArray();
        return books.length !== 0 ? books[0] : null;
    }

    static async getBookById(id) {
        let books = await window.db.libraryItem.where("id").equals(id).toArray();
        let booksContent = await window.db.libraryItemContent.where("id").equals(id).toArray();
        return books.length !== 0 || booksContent.length !== 0 ? {
            ...books[0],
            ...booksContent[0]
        } : null;
    }

    static async getTranslation(word, lang) {
        let dict = await window.db.dict.where({
            "word": word,
            "lang": lang
        }).toArray();
        return dict.length !== 0 ? dict[0].translations : null;
    }

    static async getMostCommon(from, to, lang) {
        let dict = await window.db.dict.where(["lang", "index"]).between([lang, from-1], [lang, to-1]).toArray();
        return dict.length !== 0 ? dict : null;
    }

    static async getBooks() {
        let books = await window.db.libraryItem.toArray();
        let booksProgress = await window.db.libraryItemProgress.toArray();
        if (books) {
            books = books.map(book => {
                const progress = booksProgress.find(bp => bp.id === book.id);
                return {
                    ...book,
                    progress: progress
                };
            })
            return books
        } else {
            return [];
        }
    }
    
    static async getAllUnexportedCards() {
        let cards = await window.db.cards.where("exported").equals(0).toArray();
        return cards
    }

    static async markAllAsExported() {
        let cards = await window.db.cards.modify({exported: 1});
        return cards
    }

    static async getAllCards() {
        let cards = await window.db.cards.toArray();
        return cards
    }

    // static async searchCards(keyword) {
    //     let cards = await window.db.cards.filter(card => {
    //         return (keyword) ? 
    //             ( (card.term.indexOf(keyword) !== -1) || (card.definition.indexOf(keyword) !== -1) ) : 
    //             false
    //     }).toArray();
    //     return cards.slice(0, 50).map(Utilities.prepareCardFromDb);
    // }

    static async saveSetting(key, value) {
        await window.db.settingss.put({
            key: key,
            value: value
        })
    }

    static async getSetting(key) {
        let setting = await window.db.settingss.where("key").equals(key).toArray();
        return setting.length !== 0 ? setting[0].value : null;
    }

    static async getInstanceConfig() {
        let settings = await window.db.instanceConfig.toArray();
        let settingsObj = {};
        for (let i = 0; i < settings.length; i++) {
            settingsObj[settings[i].key] = settings[i].value
        }
        return settingsObj || {};
    }

    static async getSettingsObj() {
        let settings = await window.db.settingss.toArray();
        let settingsObj = {};
        for (let i = 0; i < settings.length; i++) {
            settingsObj[settings[i].key] = settings[i].value
        }
        return settingsObj || {};
    }

    static async getSettings() {
        let settings = await window.db.settingss.toArray();
        return settings;
    }
}

export default SQLStorage;
