import { Plugins } from '@capacitor/core';
import { IonActionSheet, IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, withIonLifeCycle } from '@ionic/react';
import Axios from 'axios';
import Dexie from 'dexie';
import { add, checkmarkCircle, checkmarkDoneCircle, closeSharp } from 'ionicons/icons';
import React, { createRef } from 'react';
import TeamSvg from '../images/team.svg';
import Server from '../Server';
import SQLStorage from '../SQLStorage';
import Storage from '../Storage';
import '../styles/DropboxAuthModal.scss';
import '../styles/Settings.scss';
import '../styles/Studyset.scss';
import Utilities from '../Utilities';
import ContactUs from './ContactUs';
import ReactPlayer from 'react-player'

const { Device, Clipboard, LocalNotifications, Filesystem } = Plugins;

class Player extends React.Component {

    constructor(props) {
        super(props);
        this.videoPlayerRef = React.createRef();
        this.state = {
            playing: false,
            id: this.props.match.params.id,
            height: this.props.match.params.height,
            width: this.props.match.params.width
        }
        const iontabbar = document.querySelector('ion-tab-bar');
        if (iontabbar) {
            iontabbar.style.display = "none";
        }
    }

    componentDidMount() {
        window.addEventListener('message', function(event) {
            console.log("message in player", event)
            if (event.data && typeof event.data.type === "string" && event.data.type.startsWith("ENGLISHEDPLAYER_")) {
                const type = event.data.type.replace("ENGLISHEDPLAYER_", "");
                console.log("Message in player: " + type)
                if (type === "PLAY") {
                    this.setState({
                        playing: true
                    })
                } else if (type === "PAUSE") {
                    this.setState({
                        playing: false
                    })
                } else if (type === "SEEKTO") {
                    this.videoPlayerRef.current.seekTo(event.data.data);
                }
            }
        }.bind(this));
    }

    render() {

        return (
            <ReactPlayer
                width={this.state.width + 'px'}
                height={this.state.height + 'px'}
                controls={false}
                ref={this.videoPlayerRef}
                playing={this.state.playing} 
                loop={false}
                url={"https://www.youtube.com/watch?v=" + this.state.id}
                progressInterval={150}
                onProgress = {(p) => {
                    window.parent.postMessage({ type: "ENGLISHEDPLAYER_PROGRESS", data: p }, "*");
                }}
                onReady={() => {
                    console.log("ready");
                    window.parent.postMessage({ type: "ENGLISHEDPLAYER_READY", data: { } }, "*");
                }}
            />
        );
    }

};


export default Player;
