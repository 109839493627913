import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App.js';
import App from './AppOnlyParser.js';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: '6d3cc5b818a0b1805830f30350542fe9',
    plugins: [new BugsnagPluginReact()],
    onError: function(event) {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : { };
        // if (user && user.id)
        //     event.setUser(user.id, user.email, user.firstName + " " + user.lastName);
    }
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
ReactDOM.render(<ErrorBoundary><App /></ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
