import { Plugins } from '@capacitor/core';
import {
    IonApp,
    IonIcon, IonLabel, IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { albums, apps, book, flame, settings, statsChart } from 'ionicons/icons';
import React from 'react';
import { Route } from 'react-router-dom';
import EbookParser from './pages/EbookParser';
import Player from './pages/Player';
 
const { SplashScreen, Device, LocalNotifications, Keyboard, App } = Plugins;

interface State {
    showTabs: boolean;
}

interface Props {

}

class AppMain extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    render() {

        return (
            <IonApp data-page={this.state.currentUrl} data-os={this.state.os}>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route path="/parser" component={EbookParser} />
                        <Route path="/player/:id/:height/:width" component={Player} />
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                        <IonTabButton tab="tablibrary" href="/">
                            <IonIcon icon={book} mode="ios" />
                            <IonLabel>A</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
            </IonApp>
        )
    }

};

export default AppMain;
